import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Scheduler from "./components/scheduler/Scheduler";
import { isMobile } from "./utils/screen";
import UploadSyllabus from "./components/uploadSyllabus/UploadSyllabus";
import PrivateRoute from "./PrivateRoute";
import UserAccountRoute from "./UserAccountRoute";
import Login from "./components/auth/Login";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserContext";
import { SemesterProvider } from "./contexts/SemesterContext";
import LogoutButton from "./components/auth/LogoutButton";
import UserTable from "./components/admin/users/UserTable";
import Semester from "./components/admin/semester/Semester";
import AccountLoginPage from "./components/account/pages/AccountLoginPage";
import { AccountDashboardPage } from "./components/account/pages/AccountDashboardPage";
import {
  ACCOUNT_LOGIN_ROUTE,
  ACCOUNT_DASHBOARD_ROUTE,
  ACCOUNT_SCHEDULE_UPDATE_ROUTE,
} from "./constants/routes/accountRoutes";
import ScheduleUpdatePage from "./components/account/pages/ScheduleUpdatePage";
import { SECOUND_SEMESTER_COURSE_ROUTE } from "./constants/routes/schedulerRoutes";
import SecondSemesterCourseRegistrationPage from "./components/account/pages/SecondSemesterCourseRegistrationPage";

// TODO: Might be nice to add parent "admin/*" if there are more admin pages added in the future
const appbarRoutes = [
  {
    path: "/users",
    exact: true,
    appbar: () => <LogoutButton />,
  },
  {
    path: "/upload",
    exact: true,
    appbar: () => <LogoutButton />,
  },
];

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className={isMobile() ? "mobile" : "app"}>
        <Switch>
          <AuthProvider>
            {appbarRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.appbar />}
              />
            ))}
          </AuthProvider>
        </Switch>
        <Switch>
          <Redirect exact from="/update" to={ACCOUNT_LOGIN_ROUTE} />
          <Route path="/login">
            <Login />
          </Route>
          <UserProvider>
            <DndProvider backend={HTML5Backend}>
              <SemesterProvider>
                <Route path="/" exact>
                  <Scheduler />
                </Route>
                <Route path={ACCOUNT_LOGIN_ROUTE} exact>
                  <AccountLoginPage />
                </Route>
                <UserAccountRoute path={ACCOUNT_DASHBOARD_ROUTE} exact>
                  <AccountDashboardPage />
                </UserAccountRoute>
                <Route path={ACCOUNT_SCHEDULE_UPDATE_ROUTE} exact>
                  <ScheduleUpdatePage />
                </Route>
                <Route path={SECOUND_SEMESTER_COURSE_ROUTE} exact>
                  <SecondSemesterCourseRegistrationPage />
                </Route>
              </SemesterProvider>
            </DndProvider>
            <AuthProvider>
              <PrivateRoute path="/users" exact>
                <UserTable />
              </PrivateRoute>
              <PrivateRoute path="/upload" exact>
                <UploadSyllabus />
              </PrivateRoute>
              <PrivateRoute path="/semester" exact>
                <Semester />
              </PrivateRoute>
            </AuthProvider>
          </UserProvider>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
