import React from "react";
import useAuth from "./hooks/useAuth";
import { Route, Redirect } from "react-router-dom";
import SpinnerOverlay from "./components/shared/SpinnerOverlay";

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();

  return (
    <>
      {auth.loading ? (
        <SpinnerOverlay loading={auth.loading} />
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            auth.user ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
export default PrivateRoute;
