export default [
  "Intro",
  "Micro",
  "Macro",
  "Micro and Macro",
  "AP Macro",
  "AP Micro",
  "AP Micro and Macro",
  "Civics and Economics",
  "General Economics",
  "IB",
];
