import React from "react";
import styled from "styled-components";
import BeatLoader from "react-spinners/BeatLoader";

const SpinnerOverlay = ({ loading }) => {
  return (
    <Root $loading={loading}>
      <Container>
        <BeatLoader color={"white"} loading={loading} size={35} />
      </Container>
    </Root>
  );
};

export default SpinnerOverlay;

const Root = styled("div")`
  display: ${(props) => (props.$loading ? "" : "none")};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
