import React, { useState, createContext, useCallback } from "react";
import { getSemesters } from "../helpers/back4app";

export const SemesterContext = createContext();

function useProvideSemester() {
  const [semesters, setSemesters] = useState();

  const loadApplicationSemesters = useCallback(async () => {
    const semesters = await getSemesters();
    const semesterList = semesters.map((semester) => semester.attributes);

    setSemesters(semesterList);

    return semesterList;
  }, []);

  const getSemesterFromUserRecord = useCallback(
    (userSemesterRecord) => {
      return semesters.find(
        (semester) =>
          userSemesterRecord.semester === semester.semesterId ||
          userSemesterRecord.semesterId === semester.semesterId
      );
    },
    [semesters]
  );

  const getSemesterBySemesterId = useCallback((semesterId) => {
    return semesters.find(semester => semester.semesterId === semesterId);
  }, [semesters]);

  return {
    semesters,
    loadApplicationSemesters,
    getSemesterFromUserRecord,
    getSemesterBySemesterId,
  };
}

export const SemesterProvider = ({ children }) => {
  const semester = useProvideSemester();
  return (
    <SemesterContext.Provider value={semester}>
      {children}
    </SemesterContext.Provider>
  );
};
