import React, { useCallback, useState } from "react";
import CopySchedulerFormLabel from "./CopySchedulerFormLabel";
import Dropdown from "../../shared/Dropdown";
import styled from "styled-components";
import moment from "moment";
import DatePickerInput from "../../shared/DatePickerInput";
import Button from "../../shared/Button";
import getClosestWeekWithTopicsStartIndex from "../helper/getClosestWeekWithTopicsStartIndex";
import getClosestWeekWithTopicsEndIndex from "../helper/getClosestWeekWithTopicsEndIndex";

const dropdownSemesterMappings = new Map([
  ["Intro", "Intro to Econ"],
  ["Micro", "Microeconomics"],
  ["Macro", "Macroeconomics"],
  ["IB", "IB Economics"],
]);

export default function CopySchedulerForm({
  userSemesterSchedules,
  semesterSigningUpFor,
  onSubmit,
}) {
  const [semesterReusingId, setSemesterReusingId] = useState();
  const [courseStartDate, setCourseStartDate] = useState();

  const handleSemesterDropdownChange = useCallback((selectedDropdownOption) => {
    setSemesterReusingId(selectedDropdownOption.key);
  }, []);

  const handleCourseStartDateChange = (date) => {
    setCourseStartDate(date);
  };

  const handleSignUpSumission = useCallback(() => {
    onSubmit({ courseStartDate, semesterReusingId });
  }, [courseStartDate, onSubmit, semesterReusingId]);

  const previousTopicsDropdownOptions = userSemesterSchedules.map(
    (semester) => {
      const subjectName =
        dropdownSemesterMappings.get(semester.subject) ?? semester.subject;

      const startWeekIndex = getClosestWeekWithTopicsStartIndex(semester.weeks);
      const endWeekIndex = getClosestWeekWithTopicsEndIndex(semester.weeks);

      const scheduleStartDate = moment(
        new Date(semester.weeks[startWeekIndex].date)
      ).format("MMM D");

      const scheduleEndDate = moment(
        new Date(semester.weeks[endWeekIndex].date)
      ).format("MMM D, YYYY");

      const dropdownLabelValue = `${subjectName} (${scheduleStartDate}-${scheduleEndDate})`;
      return {
        key: semester.semesterId ?? semester.semester,
        value: dropdownLabelValue,
      };
    }
  );

  return (
    <Root>
      <CopySchedulerFormLabel>
        Previous course topics you want to reuse
      </CopySchedulerFormLabel>
      <Dropdown
        options={previousTopicsDropdownOptions}
        onChange={handleSemesterDropdownChange}
      />
      <div>
        <RedirectLink href="https://www.econinbox.com/signup">
          No, I want to start from scratch.
        </RedirectLink>
      </div>
      {semesterReusingId && (
        <CourseStartDateContainer>
          <CopySchedulerFormLabel>
            Your course start date
          </CopySchedulerFormLabel>
          <DatePickerInput
            onFocus={(e) => e.target.blur()}
            placeholderText="Select date"
            onChange={(date) => handleCourseStartDateChange(date)}
          />
        </CourseStartDateContainer>
      )}
      {courseStartDate && (
        <SignUpButton onClick={handleSignUpSumission}>Sign Up</SignUpButton>
      )}
    </Root>
  );
}

const Root = styled("div")`
  margin: 2rem 0;
`;

const SignUpButton = styled(Button)`
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
`;

const RedirectLink = styled("a")`
  display: block;
  color: white;
  font-size: 1.2rem;
  margin-top: 15px;
  :hover {
    opacity: 0.8;
  }
`;

const CourseStartDateContainer = styled("div")`
  width: 100%;
  margin: 2rem 0;

  .calender-w-icon {
    margin: 0 auto;
  }
`;
