import React, { useCallback, useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import TextInput from "../../shared/TextInput";
import Button from "../../shared/Button";
import useInputState from "@emberex/react-utils/lib/useInputState";

export default function SemesterEditForm({ onSubmit, semester }) {
  const initSemesterName = semester?.displayName ?? "";
  const initOutputName = semester?.outputName ?? "";
  const initSemesterId = semester?.semesterId ?? "";
  const initStartDate = semester?.startDate ? new Date(semester.startDate) : "";
  const initEndDate = semester?.endDate ? new Date(semester.endDate) : "";

  const [displayName, handleDisplayNameChange] = useInputState(
    initSemesterName
  );
  const [outputName, handleOutputNameChange] = useInputState(initOutputName);
  const [semesterId, handleSemesterIdChange] = useInputState(initSemesterId);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      displayName,
      outputName,
      semesterId,
      startDate,
      endDate,
    });
  }, [displayName, endDate, onSubmit, outputName, semesterId, startDate]);

  return (
    <Root>
      <div>
        <InputLabel>Display Name</InputLabel>
        <Input value={displayName} onChange={handleDisplayNameChange} />
      </div>
      <div>
        <InputLabel>CSV Output Name</InputLabel>
        <Input value={outputName} onChange={handleOutputNameChange} />
      </div>
      <div>
        <InputLabel>Semester UTM</InputLabel>
        <Input value={semesterId} onChange={handleSemesterIdChange} />
      </div>
      <DateRange>
        <DateRangeItem>
          <InputLabel>Start Date</InputLabel>
          <DatePicker
            onFocus={(e) => e.target.blur()}
            placeholderText="Select Start Date"
            className="picker"
            selected={startDate}
            minDate={new Date("2021/01/04")}
            onChange={(date) => handleStartDateChange(date)}
          />
        </DateRangeItem>
        <DateRangeItem>
          <InputLabel>End Date</InputLabel>
          <DatePicker
            onFocus={(e) => e.target.blur()}
            placeholderText="Select End Date"
            className="picker"
            selected={endDate}
            minDate={new Date("2021/01/04")}
            onChange={(date) => handleEndDateChange(date)}
          />
        </DateRangeItem>
      </DateRange>
      <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
    </Root>
  );
}

const Root = styled("div")`
  padding: 0 2.5rem;
  display: flex;
  flex-direction: column;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    border: 2px solid #e3e3e3;
    border-radius: 4px;
  }

  .picker {
    margin-top: 5px;
    width: 100%;
    color: #000000;
    border: none;
    padding: 14px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
  }
`;

const DateRange = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DateRangeItem = styled("div")`
  width: 48%;
`;

const InputLabel = styled("div")`
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

const Input = styled(TextInput)`
  border: 2px solid #e3e3e3;
  width: 100%;
  font-size: 1rem;
  padding: 14px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 1.25rem;
  padding: 14px;
  font-size: 1rem;
  font-weight: 500;
`;
