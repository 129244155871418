import { EXAM } from "../../constants/topics";
export default function alternateTopicNameMappings(weeks) {
  return weeks.map((week) => {
    if (week) {
      return week.map((topic) => {
        if (topic === EXAM) {
          return "Exam - General";
        }

        if (topic === "Game Theory and Oligopoly") {
          return "Oligopoly";
        }

        if (topic === "Economic Growth") {
          return "Long-run Growth";
        }

        if (topic === "Government Budget") {
          return "Government Deficits";
        }

        return topic;
      });
    }
    return week;
  });
}
