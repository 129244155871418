import React from "react";
import { useDrag } from "react-dnd";
import TopicContainer from "../scheduler/shared/TopicContainer";
import xMarkSrc from "../../assets/images/x-mark.png";
import styled from "styled-components";

const DraggableBox = function DraggableBox({
  name,
  borderColor,
  currentWeekNumber,
  onRemove,
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "topic",
    item: { name, currentWeekNumber },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  const removeable = typeof onRemove === "function";
  return (
    <TopicContainer
      ref={drag}
      role="topic"
      style={{ opacity, borderColor }}
      data-testid={`box-${name}`}
    >
      {name}
      {removeable && (
        <RemoveButton onClick={onRemove}>
          <XIcon />
        </RemoveButton>
      )}
    </TopicContainer>
  );
};

export default DraggableBox;

const RemoveButton = styled("button")`
  position: absolute;
  right: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  margin-right: 5px;
  cursor: pointer;
  img {
    width: 15px;
    height: auto;
  }
`;

const XIcon = styled("img").attrs({ src: xMarkSrc, alt: "X Icon" })``;
