import React from "react";
import useOnClickOutside from "@emberex/react-utils/lib/useOnClickOutside";
import "./Modal.css";

export default function Modal({ toggleModal, open, header, children }) {
  const handleCloseModal = () => {
    toggleModal(false);
  };

  const modalRef = useOnClickOutside(handleCloseModal);

  return (
    <>
      {open && (
        <div className="modal">
          <span
            onClick={handleCloseModal}
            className="close"
            title="Close Modal"
          >
            &times;
          </span>
          <div ref={modalRef} className="modal-content">
            <div className="container">
              <div>
                <h1 className="modal-header">{header}</h1>
              </div>
              <div className="modal-text">{children}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
