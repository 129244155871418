import React, { useCallback, useState } from "react";
import Row from "../shared/Row";
import RadioButton from "../shared/RadioButton";
import { getCourseLengths } from "../../api/course-templates";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";

export default function CourseLengthRadioSelection({ onChange }) {
  const [selectedCourseLength, setSelectedCourseLength] = useState();
  const [courseLengthOptions, setCourseLengthOptions] = useState([]);

  const handleRadioButtonChange = useCallback(
    (value) => {
      setSelectedCourseLength(value);
      onChange(value);
    },
    [onChange]
  );

  useAsyncEffect(async () => {
    const courseLengths = await getCourseLengths();

    setCourseLengthOptions(
      courseLengths.map((courseLength) => {
        return { id: courseLength.id, ...courseLength.attributes };
      })
    );
  }, []);
  return (
    <Row>
      {courseLengthOptions.map((courseLengthOption) => {
        return (
          <RadioButton
            key={courseLengthOption.id}
            label={courseLengthOption.name}
            checked={selectedCourseLength?.id === courseLengthOption.id}
            onClick={() => {
              handleRadioButtonChange(courseLengthOption);
            }}
          />
        );
      })}
    </Row>
  );
}
