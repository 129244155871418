import styled from "styled-components";
import logoSrc from "../../assets/images/econinbox-logo.png";

export default styled("img").attrs({
  src: logoSrc,
  alt: "EconInbox Logo",
})`
  width: 170px;
  height: auto;
`;
