import {
  CUSTOM_TOPIC,
  EXAM,
  LAST_CLASS,
  DUPLICATE_TOPIC_EMAIL,
  WEEK_BREAK,
  MULTIPLE_EMAIL_NOTIFICATION,
} from "../../constants/topics";

export default function multipleEmailNotifications(weeks) {
  const topicsToExclude = [
    EXAM,
    CUSTOM_TOPIC,
    LAST_CLASS,
    DUPLICATE_TOPIC_EMAIL,
  ];
  return weeks.map((topicList) => {
    if (topicList && topicList.length > 1) {
      let trueTopicCount = 0;

      for (let topic of topicList) {
        const isTrueTopic =
          topic &&
          !topicsToExclude.includes(topic) &&
          !topic.includes(WEEK_BREAK);

        if (isTrueTopic) {
          trueTopicCount++;
        }
      }

      return trueTopicCount > 1
        ? [MULTIPLE_EMAIL_NOTIFICATION, ...topicList]
        : topicList;
    } else {
      return topicList;
    }
  });
}
