import {
  EXAM,
  DUPLICATE_TOPIC_EMAIL,
  WEEK_BREAK,
} from "../../constants/topics";

export default function examMappings(weeks) {
  let delayedWeekBreaks = [];

  let newWeeks = weeks.map((topicList, index) => {
    if (topicList && topicList.length > 1 && topicList.includes(EXAM)) {
      const filteredTopicList = topicList.filter((topic) => {
        if (topic.includes(WEEK_BREAK)) {
          // Handle exam with duplicate topic breaks
          const weekNumber = topic.match(/\d+/) - 1;
          delayedWeekBreaks.push({
            index,
            topic: `Duplicate Topic - ${weekNumber} Week Break`,
          });
          return false;
        }

        if (topic.includes(DUPLICATE_TOPIC_EMAIL)) {
          return false;
        }

        return true;
      });

      if (filteredTopicList.length > 1) {
        return filteredTopicList.filter((topic) => topic !== EXAM);
      } else {
        return filteredTopicList;
      }
    } else {
      return topicList;
    }
  });

  if (delayedWeekBreaks.length) {
    for (let weekBreak of delayedWeekBreaks) {
      newWeeks[weekBreak.index + 1] = [
        ...newWeeks[weekBreak.index + 1],
        weekBreak.topic,
      ];
    }
  }

  return newWeeks;
}
