import {
  CUSTOM_TOPIC,
  EXAM,
  DUPLICATE_TOPIC_EMAIL,
  WEEK_BREAK,
} from "../../constants/topics";
/* Duplicate topic mappings */
// If consecutive, (2) => "Math 101", "Custom Topic Email - 1"
// If consecutive, (>2) =? "Math 101", "Duplicate Topic - X Week Break", "", ...
// If duplicate but NOT consecutive, leave blank => "Math 101", "Science 200", "", ...
// If duplicate and 10 weeks have passed, resend email => "Math 101", ... ,"Math 101"

export default function duplicateTopicMappings(weeks) {
  let weeksMap = new Map();

  const uniqueWeeks = weeks.map((topicList, weekNumber) => {
    if (topicList) {
      return topicList.map((currentTopic) => {
        if (currentTopic === CUSTOM_TOPIC || currentTopic === EXAM) {
          // Ignore custom topics
          return currentTopic;
        }

        if (weeksMap.has(currentTopic)) {
          // Duplicate topic found, get its previous index locations
          const topicIndexOccurrences = weeksMap.get(currentTopic);

          if (topicIndexOccurrences.length >= 2) {
            let previousTwoWeeks = [...topicIndexOccurrences].splice(
              topicIndexOccurrences.length - 2
            );

            if (previousTwoWeeks.reduce((a, b) => b - a) === 1) {
              // Current topic is part of duplicate topic break
              return "";
            }
          }

          if (topicIndexOccurrences.includes(weekNumber - 1)) {
            // Consecutive duplicate, check if there are any future ones
            const upcomingWeeksFromCurrentWeek = [
              ...[...weeks].splice(weekNumber + 1),
            ];
            let weekBreakCount = 1;

            for (let futureTopicList of upcomingWeeksFromCurrentWeek) {
              let prevCount = weekBreakCount;
              for (let currentFutureTopic of futureTopicList) {
                if (currentFutureTopic === currentTopic) {
                  weekBreakCount++;
                  break;
                }
              }
              if (weekBreakCount === prevCount) {
                break;
              }
            }

            weeksMap.set(currentTopic, [...topicIndexOccurrences, weekNumber]);

            // Determine whether current week is duplicate topic email or X week break
            return weekBreakCount > 1
              ? `Duplicate Topic - ${weekBreakCount} Week Break`
              : DUPLICATE_TOPIC_EMAIL;
          } else {
            weeksMap.set(currentTopic, [...topicIndexOccurrences, weekNumber]);
            const topicOccurrenceCount = topicIndexOccurrences.length;
            // Not consecutive, check if 10 weeks or more have passed else return empty week
            if (
              weekNumber - topicIndexOccurrences[topicOccurrenceCount - 1] >=
              10
            ) {
              return currentTopic;
            } else {
              return "";
            }
          }
        } else {
          weeksMap.set(currentTopic, [weekNumber]);
          return currentTopic;
        }
      });
    } else {
      return topicList;
    }
  });

  return uniqueWeeks.map((week) => {
    if (Array.isArray(week)) {
      const weeksWithMultipleBreaks = week.filter((topic) =>
        topic.includes(WEEK_BREAK)
      );
      if (weeksWithMultipleBreaks.length > 1) {
        return week.filter((topic) => !weeksWithMultipleBreaks.includes(topic));
      }
    }
    return week;
  });
}
