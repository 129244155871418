import React, { useEffect, useState } from "react";
import Tab from "./Tab";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import useUser from "../../hooks/useUser";
import styled from "styled-components";
import useSemester from "../../hooks/useSemester";
import { Link } from "react-router-dom";
import { ACCOUNT_DASHBOARD_ROUTE } from "../../constants/routes/accountRoutes";

export default function SemesterTabs({ children }) {
  const [loading, setLoading] = useState(false);
  const [semesterTabs, setSemesterTabs] = useState();
  const { semesterSchedules, setActiveSemesterId, activeSemesterId } =
    useUser();

  const { getSemesterFromUserRecord, semesters } = useSemester();

  useEffect(() => {
    setLoading(true);
    if (Array.isArray(semesterSchedules) && activeSemesterId && semesters) {
      setSemesterTabs(
        semesterSchedules.map((userSemester) => {
          const semesterObj = getSemesterFromUserRecord(userSemester);
          return { name: semesterObj.displayName, id: semesterObj.semesterId };
        })
      );
    }
    setLoading(false);
  }, [
    activeSemesterId,
    getSemesterFromUserRecord,
    semesterSchedules,
    semesters,
  ]);

  return (
    <Root>
      {semesterTabs ? (
        <div>
          <TabContainer>
            <Tabs>
              {semesterTabs.map((semester) => {
                return (
                  <Tab
                    active={semester.id === activeSemesterId}
                    label={semester.name}
                    key={semester.id}
                    onTabClick={() =>
                      setActiveSemesterId(
                        semester.id ?? semester.name,
                        semester.name
                      )
                    }
                  />
                );
              })}
            </Tabs>
            {semesterSchedules?.length > 1 && (
              <AccountButtonContainer>
                <AccountLink to={ACCOUNT_DASHBOARD_ROUTE}>
                  My Account
                </AccountLink>
              </AccountButtonContainer>
            )}
          </TabContainer>
          <div> {children}</div>
        </div>
      ) : (
        <SpinnerOverlay loading={loading} />
      )}
    </Root>
  );
}

const Root = styled("div")`
  width: 100vw;
  height: 100vh;
  padding: 2.5rem 150px;
  overflow-y: scroll;
  text-align: center;
`;

const TabContainer = styled("div")`
  overflow-x: scroll;
  display: inline-flex;
  justify-content: space-between;
  width: 1050px;
  background-color: #e0e0e0;
`;

const Tabs = styled("div")`
  overflow-x: scroll;
  display: inline-flex;
  background-color: #e0e0e0;
`;

const AccountButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  width: 160px;
`;

const AccountLink = styled(Link)`
  font-weight: 400;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 130px;
  height: 35px;
  border-radius: 8px;
  background-color: #3182bd;
`;
