import parse from "../parse";
import { UserDataClass } from "../../constants/classes";

export default async function getUserFromSemesterId({ email, semesterId }) {
  const user = parse.Object.extend(UserDataClass);
  const query = new parse.Query(user);
  query.equalTo("email", email.toLowerCase());
  query.equalTo("semesterId", semesterId);
  return await query.find();
}
