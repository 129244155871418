import {
  EXAM,
  DUPLICATE_TOPIC_EMAIL,
  LAST_SCHEDULED_EMAIL,
  FINAL_FEEDBACK_EMAIL,
  MID_SEMESTER_FEEDBACK_EMAIL,
  CUSTOM_TOPIC,
} from "../../constants/topics";
import shiftRight from "../shiftRight";
import shiftLeft from "../shiftLeft";

export default function feedbackEmailMappings(
  weekTopicsArray,
  weekObjectsArray
) {
  let sortedWeeksObjectArray = [...weekObjectsArray].sort((a, b) => {
    const aDate = new Date(a.date);
    const bDate = new Date(b.date);
    return aDate.getTime() < bDate.getTime()
      ? -1
      : aDate.getTime() > bDate.getTime()
      ? 1
      : 0;
  });

  // Assign weeks with mappings to sortedWeeksObjectArray
  const weeksWithOriginalWeekPositions = shiftRight(weekTopicsArray);
  sortedWeeksObjectArray = sortedWeeksObjectArray.map((weekObj) => {
    return {
      ...weekObj,
      topic: weeksWithOriginalWeekPositions[weekObj.numberOfWeek - 1],
    };
  });

  let startWeekIndex;
  let endWeekIndex;

  // End week number index
  for (let i = sortedWeeksObjectArray.length - 1; i > 0; i--) {
    let topics = sortedWeeksObjectArray[i].topic;
    if (Array.isArray(topics)) {
      if (
        (topics.length === 1 && topics.includes(CUSTOM_TOPIC)) ||
        topics.includes(DUPLICATE_TOPIC_EMAIL)
      ) {
        sortedWeeksObjectArray[i].topic = "";
      } else {
        if (topics.length) {
          endWeekIndex = i;
          break;
        }
      }
    }
  }

  // Start week number index
  for (let i = 0; i < sortedWeeksObjectArray.length - 1; i++) {
    if (Array.isArray(sortedWeeksObjectArray[i].topic)) {
      const truthyTopics = sortedWeeksObjectArray[i].topic.filter(
        (topic) => topic
      );
      if (truthyTopics.length) {
        startWeekIndex = i;
        break;
      }
    }
  }

  // Last scheduled email
  let lastScheduledEmailWeekIndex;
  if (sortedWeeksObjectArray.length >= 4 && endWeekIndex) {
    for (let i = endWeekIndex; i > 0; i--) {
      const duplicateEmailExists = sortedWeeksObjectArray[i].topic.includes(
        DUPLICATE_TOPIC_EMAIL
      );
      const includesExam = sortedWeeksObjectArray[i].topic.includes("Exam");
      const topicCount = sortedWeeksObjectArray[i].topic.length;

      if (
        topicCount > 1 ||
        (topicCount === 1 && !includesExam && !duplicateEmailExists)
      ) {
        lastScheduledEmailWeekIndex = i;
        break;
      }
    }

    if(sortedWeeksObjectArray[lastScheduledEmailWeekIndex]?.topic){
      sortedWeeksObjectArray[lastScheduledEmailWeekIndex].topic = [
        LAST_SCHEDULED_EMAIL,
        ...sortedWeeksObjectArray[lastScheduledEmailWeekIndex]?.topic,
      ];
    }
  }

  // Clear items after final feedback email
  for (
    let i = lastScheduledEmailWeekIndex + 1;
    i < sortedWeeksObjectArray.length;
    i++
  ) {
    if (sortedWeeksObjectArray[i] !== undefined) {
      sortedWeeksObjectArray[i].topic = "";
    }
  }

  // Mid semester
  if (lastScheduledEmailWeekIndex - startWeekIndex > 8) {
    let midSemesterIndex = Math.floor(
      (startWeekIndex + lastScheduledEmailWeekIndex) / 2
    );

    const option1 = goodTimeForMidSemesterEmail(
      sortedWeeksObjectArray[midSemesterIndex].topic
    );
    const option2 = goodTimeForMidSemesterEmail(
      sortedWeeksObjectArray[midSemesterIndex - 1].topic
    );
    const option3 = goodTimeForMidSemesterEmail(
      sortedWeeksObjectArray[midSemesterIndex + 1].topic
    );

    if (option1) {
      sortedWeeksObjectArray[midSemesterIndex].topic = option1;
    } else if (option2) {
      sortedWeeksObjectArray[midSemesterIndex - 1].topic = option2;
    } else if (option3) {
      sortedWeeksObjectArray[midSemesterIndex + 1].topic = option3;
    } else {
      sortedWeeksObjectArray[midSemesterIndex].topic = [
        MID_SEMESTER_FEEDBACK_EMAIL,
        ...sortedWeeksObjectArray[midSemesterIndex].topic,
      ];
    }
  }

  // Final feedback email
  const finalFeedbackEmailWeekNumber = !lastScheduledEmailWeekIndex
    ? null
    : sortedWeeksObjectArray[lastScheduledEmailWeekIndex].numberOfWeek ===
      weekTopicsArray.length
    ? 1
    : sortedWeeksObjectArray[lastScheduledEmailWeekIndex].numberOfWeek + 1;

  return shiftLeft(
    weekTopicsArray
      .map(() => "")
      .map((week, i) => {
        const existingWeek = sortedWeeksObjectArray.find(
          (week) => week.numberOfWeek - 1 === i
        );
        if (finalFeedbackEmailWeekNumber - 1 === i) {
          return [FINAL_FEEDBACK_EMAIL];
        }

        return existingWeek ? existingWeek.topic : week;
      })
  );
}

function goodTimeForMidSemesterEmail(topicList) {
  if (!topicList) {
    return [MID_SEMESTER_FEEDBACK_EMAIL];
  }

  if (Array.isArray(topicList)) {
    let tmpTopicList = [...topicList];
    const indexOfExam = topicList.indexOf(EXAM);
    const indexOfDuplicateTopicEmail = topicList.indexOf(DUPLICATE_TOPIC_EMAIL);

    if (indexOfExam !== -1 && topicList.length === 1) {
      tmpTopicList[indexOfExam] = MID_SEMESTER_FEEDBACK_EMAIL;
      return tmpTopicList;
    }

    if (indexOfDuplicateTopicEmail !== -1) {
      tmpTopicList[indexOfDuplicateTopicEmail] = MID_SEMESTER_FEEDBACK_EMAIL;
      return tmpTopicList;
    }

    if (topicList.length === 1) {
      return [MID_SEMESTER_FEEDBACK_EMAIL, ...topicList];
    }
  }

  return;
}
