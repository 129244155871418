import React, { useState, createContext, useEffect } from "react";
import Parse from "parse";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext({
  user: null,
  signOut: null,
  loading: true,
});

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const signOut = () => {
    Parse.User.logOut();
    setUser(null);
    history.push("/login");
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const currentUser = await Parse.User.currentAsync();
        if (currentUser) {
          setUser(currentUser);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  return {
    user,
    signOut,
    loading,
  };
}

export const AuthProvider = (props) => {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  );
};
