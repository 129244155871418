import React from "react";
import styled from "styled-components";

export default function Tab({ active, onTabClick, label }) {
  return (
    <TabButton active={active} onClick={onTabClick}>
      {label}
    </TabButton>
  );
}

const TabButton = styled("div")`
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  padding: 0.7rem 25px;
  text-transform: uppercase;
  color: ${(props) => (props.active ? "#3f3f3f" : "#898989")};
  background-color: ${(props) => (props.active ? "#fff" : " #e0e0e0")};
  border-bottom: ${(props) => (props.active ? "6px solid #0db0dc" : "none")};
`;
