import leapYear from "../utils/leapYear";
const generateWeeks = () => {
  const today = new Date();
  const totalWeeks = leapYear(today.getFullYear()) ? 53 : 52;

  let weeks = [];
  for (let i = 1; i <= totalWeeks; i++) {
    weeks.push(`Week ${i}`);
  }
  return weeks;
};

export const CSV_HEADER = [
  "Semester",
  "Processed",
  "Updated",
  "Processsed Update",
  "EconInboxId",
  "Custom Start Date",
  "EconInbox Email",
  "EX - I'm teaching",
  "EX - Date/Time Application Received",
  ...generateWeeks(),
  "Custom Topics",
];
