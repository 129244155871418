import React, { useCallback } from "react";
import useInputState from "@emberex/react-utils/lib/useInputState";
import Button from "../shared/Button";
import styled from "styled-components";

export default function AddCustomTopicForm({ onAddCustomTopic }) {
  const [topicName, handleTopicNameChange] = useInputState();

  const handleAddCustomTopic = useCallback(() => {
    onAddCustomTopic(topicName);
  }, [onAddCustomTopic, topicName]);

  return (
    <Root>
      <Input
        placeholder="Enter custom topic"
        onChange={handleTopicNameChange}
      />
      <AddButton onClick={handleAddCustomTopic}>Add</AddButton>
    </Root>
  );
}

const Root = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Input = styled("input")`
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0.5rem 1rem;
  width: 180px;
  font-size: 13px;
`;

const AddButton = styled(Button)`
  height: 33px;
  width: 50px;
`;
