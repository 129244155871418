/**
 * 
 * @param params Array of object(s) { key, value }
 */
export default function generateUrlWithParams(params) {
  let urlParams = [];
  for (const paramObj of params) {
    urlParams = [...urlParams, `${encodeURI(paramObj.key)}=${encodeURI(paramObj.value)}`];
  }
  return `?${encodeURI(urlParams.join('&'))}`;
}