import React, { useCallback, useState } from "react";
import EconInboxLogo from "../shared/EconInboxLogo";
import TemplateSchedulerForm from "./TemplateSchedulerForm";
import {
  findCourseTemplateMapping,
  getCourseTemplateById,
} from "../../api/course-templates";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import {
  generateSemesterWeeks,
  getWeekNumberFromDate,
} from "../../utils/semesterDates";
import hasMicroAndMacroTopics from "../../utils/hasMicroAndMacroTopics";
import { saveUserAsync } from "../../helpers/back4app";
import { getUpcomingCourseHolidaysForCourse } from "../../api/courses";
import useUser from "../../hooks/useUser";
import CUSTOM_TOPICS from "../../constants/customTopics";
import SemesterTemplateSubmissionConfirmation from "../confirmation/SemesterTemplateSubmissionConfirmation";
import moment from "moment";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { ACCOUNT_DASHBOARD_ROUTE } from "../../constants/routes/accountRoutes";
import Swal from "sweetalert2";

const SWAL_DIALOG_WIDTH = 400;

export default function TemplateScheduler({ urlParams }) {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState();
  const [selectedCourseLength, setSelectedCourseLength] = useState();
  const [selectedCoursePacing, setSelectedCoursePacing] = useState();
  const [showCoursePacings, setShowCoursePacings] = useState(false);
  const [didSubmitSchedule, setDidSubmitSchedule] = useState(false);
  const { setSemesterSchedules } = useUser();
  const history = useHistory();

  const { teaching, email, semester } = urlParams;

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    let redirectToBuilder;
    try {
      if (
        !startDate ||
        !selectedCourseLength ||
        (showCoursePacings && !selectedCoursePacing)
      ) {
        Swal.fire({
          icon: "warning",
          text: "Please fill out all fields.",
          width: SWAL_DIALOG_WIDTH,
        });
        return;
      }

      const [templateMapping] = await findCourseTemplateMapping({
        courseName: teaching,
        courseLengthId: selectedCourseLength.id,
        coursePacingId: selectedCoursePacing?.key,
      });

      if (!templateMapping) {
        Swal.fire({
          icon: "warning",
          text: "Unable to find semester template",
          width: SWAL_DIALOG_WIDTH,
        });
        return;
      }

      const templateId = templateMapping.attributes.template.id;

      const [courseTemplate] = await getCourseTemplateById(templateId);
      const templateWeeks = courseTemplate.attributes.defaultWeeks;

      // Check for potential holidays
      const holidaysMap = new Map();
      const holidaysParseArray = await getUpcomingCourseHolidaysForCourse({
        courseId: templateMapping.attributes.course.id,
        date: startDate,
      });

      if (holidaysParseArray) {
        for (const holidayObj of holidaysParseArray) {
          holidaysMap.set(
            getWeekNumberFromDate(holidayObj.attributes.date),
            holidayObj.attributes.name
          );
        }
      }

      let templateWeeksStack = [...templateWeeks];
      const weekCount =
        templateWeeks.length +
        generateSemesterWeeks(startDate, templateWeeks.length).filter((week) =>
          holidaysMap.has(week.numberOfWeek)
        ).length;

      const weeksGeneratedFromTemplate = generateSemesterWeeks(
        startDate,
        weekCount
      ).map((weekObj) => {
        // Fill in weeks from course template
        const weekNumber = weekObj.numberOfWeek;
        return holidaysMap.has(weekNumber)
          ? { ...weekObj, topic: [holidaysMap.get(weekNumber)] }
          : {
              ...weekObj,
              topic: templateWeeksStack
                ?.shift()
                .split(";")
                .map((topic) => topic.trim()),
            };
      });

      const microAndMacro = hasMicroAndMacroTopics({
        subject: teaching,
        weeks: weeksGeneratedFromTemplate,
      });

      const newSemester = {
        email: email,
        subject: teaching,
        weeks: weeksGeneratedFromTemplate,
        semesterId: semester,
        userId: urlParams.exid,
        startDate: moment(startDate).format("YYYY/M/D") ?? "",
        customTopics: CUSTOM_TOPICS,
        isUpdate: false,
        processed: false,
        processedUpdate: false,
        updated: false,
        microAndMacro,
      };

      await saveUserAsync(newSemester);
      setSemesterSchedules((prevSemesters) => [...prevSemesters, newSemester]);
      redirectToBuilder = !courseTemplate.attributes.createOnSignUp;
      if (!redirectToBuilder) {
        setDidSubmitSchedule(true);
      }
    } catch (e) {
      Swal.fire({
        icon: "error",
        text: e,
        width: SWAL_DIALOG_WIDTH,
      });
    } finally {
      setLoading(false);
    }

    if (redirectToBuilder && history) {
      // Redirect to schedule builder
      history.push(`${ACCOUNT_DASHBOARD_ROUTE}?email=${email}`);
    }
  }, [
    history,
    email,
    selectedCourseLength,
    selectedCoursePacing,
    semester,
    setSemesterSchedules,
    showCoursePacings,
    startDate,
    teaching,
    urlParams.exid,
  ]);

  const handleStartDateChange = useCallback((value) => {
    setStartDate(value);
  }, []);

  const handleCourseLengthChange = useCallback(
    async (value) => {
      setLoading(true);
      try {
        const courseTemplates = await findCourseTemplateMapping({
          courseName: teaching,
          courseLengthId: value.id,
        });

        const hasPacings = courseTemplates.find(
          (courseTemplate) => courseTemplate.attributes.coursePacing
        )
          ? true
          : false;

        setShowCoursePacings(hasPacings);
        if (!hasPacings) {
          setSelectedCoursePacing(null);
        }
        setSelectedCourseLength(value);
      } catch (e) {
        alert(e.message);
      } finally {
        setLoading(false);
      }
    },
    [teaching]
  );

  const handleCoursePacingChange = useCallback((value) => {
    setSelectedCoursePacing(value);
  }, []);

  return (
    <Root didSubmitSchedule={didSubmitSchedule}>
      {!didSubmitSchedule ? (
        <>
          <EconInboxLogo />
          <SpinnerOverlay loading={loading} />
          <TemplateSchedulerForm
            onSubmit={handleSubmit}
            showCoursePacings={showCoursePacings}
            starDate={startDate}
            onStartDateChange={handleStartDateChange}
            onCourseLengthChange={handleCourseLengthChange}
            onCoursePacingChange={handleCoursePacingChange}
          />
        </>
      ) : (
        <SemesterTemplateSubmissionConfirmation
          showAccountLink
          userEmail={email}
          semesterId={semester}
        />
      )}
    </Root>
  );
}

const Root = styled("div")`
  padding: 10px 5px;
  width: 475px;
  height: 100vh;
  text-align: center;
  ${(props) =>
    props.didSubmitSchedule
      ? `
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  `
      : ``}

  ${EconInboxLogo} {
    margin-bottom: 1rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`;
