import React, { useCallback } from "react";
import Bucket from "../dnd/Bucket";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import DraggableBox from "../dnd/DraggableBox";

export default function TopicsBucket({
  activeSemesterId,
  weekIndex,
  date,
  topics,
  weekNumber,
  onTopicDrop,
  onRemoveTopic,
}) {
  const generateDateLabel = useCallback(() => {
    const startDate = moment(date);
    const nextWeek = moment(date).endOf("isoWeek");
    const endDate = nextWeek.isSame(startDate, "month")
      ? nextWeek.format("D")
      : nextWeek.format("MMM. D");

    return `${moment(startDate).format("MMM. D")}-${endDate}`;
  }, [date]);

  const dateLabel = generateDateLabel();

  return (
    <>
      <DateLabel>
        Week {weekIndex + 1} - {dateLabel}
      </DateLabel>
      {topics.map((topicName, i) => {
        return (
          <DraggableBox
            name={topicName}
            currentWeekNumber={weekNumber}
            key={`${topicName}-${i}-${weekIndex}`}
            type={activeSemesterId}
            onRemove={() => onRemoveTopic(topicName, weekNumber)}
          />
        );
      })}

      <Bucket weekNumber={weekNumber} onDrop={onTopicDrop} key={uuidv4()} />
    </>
  );
}

const DateLabel = styled("div")`
  font-size: 13px;
  margin-top: 2rem;
  margin-bottom: 5px;
`;
