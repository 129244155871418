export default function getSelectedSubject(subject) {
  const selectedSubject = subject.toLowerCase();
  if (selectedSubject === "macro" || selectedSubject === "ap macro") {
    return "macro";
  } else if (selectedSubject === "intro") {
    return "intro";
  } else {
    return "micro";
  }
}
