import moment from "moment";

export function getWeekNumberFromDate(date) {
  return moment(date).isoWeek();
}

export function getMondayDateFromDate(date) {
  const monday = moment(date).clone().isoWeekday(1);
  return monday.format("YYYY/MM/D");
}

export function generateSemesterWeeks(startDate, weekCount) {
  let weeks = [];
  let currentDate = getMondayDateFromDate(startDate);
  let currentWeekNumber = getWeekNumberFromDate(currentDate);

  for (let i = 0; i < weekCount; i++) {
    weeks.push({
      date: currentDate,
      numberOfWeek: currentWeekNumber,
      label: moment(currentDate).format("ddd. MMM DD"),
      topic: [],
    });
    currentDate = getMondayDateFromDate(moment(currentDate).add(1, "week"));
    currentWeekNumber = getWeekNumberFromDate(currentDate);
  }

  return weeks;
}

export function generateUpcomingWeek(date) {
  const nextMondayDate = moment(date).add(1, "week");
  const weekNumber = getWeekNumberFromDate(nextMondayDate);

  return {
    date: nextMondayDate.format("YYYY/MM/D"),
    numberOfWeek: weekNumber,
    label: nextMondayDate.format("ddd. MMM DD"),
    topic: [],
  };
}
