import React, { useCallback, useEffect, useState } from "react";
import WeekItem from "./WeekItem";
import Dropdown from "../../shared/Dropdown";
import useSemester from "../../../hooks/useSemester";
import AddTopicModal from "./AddTopicModal";
import Button from "../../shared/Button";
import styled from "styled-components";
import useUser from "../../../hooks/useUser";

export default function WeekTopicsManager({ onSubmit, semesterUrlParam }) {
  const [isNewSemester, setIsNewSemester] = useState(false);
  const [userWeeks, setUserWeeks] = useState([]);
  const [addTopicWeekNumber, setAddTopicWeekNumber] = useState();
  const [showTopicsModal, setShowTopicsModal] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [currentSubject, setCurrentSubject] = useState();

  const {
    activeSemesterId,
    semesterSchedules,
    getActiveSemester,
    setActiveSemesterId,
    removeTopicFromActiveSemester,
    addTopicToActiveSemester,
    removeWeekFromSemesterSchedule,
    addWeekToSemesterSchedule,
  } = useUser();

  const { getSemesterFromUserRecord } = useSemester();

  useEffect(() => {
    const activeSemester = getActiveSemester();

    setCurrentSubject(activeSemester.subject);
    setIsNewSemester(
      semesterUrlParam && semesterUrlParam === activeSemester.semesterId
    );

    if (activeSemester) {
      setUserWeeks(
        activeSemester.weeks.map((week) => {
          return {
            date: week.date,
            topics: week.topic,
            weekNumber: week.numberOfWeek,
          };
        })
      );
    }
  }, [getActiveSemester, activeSemesterId, semesterUrlParam]);

  useEffect(() => {
    if (semesterSchedules.length) {
      setDropdownOptions(
        semesterSchedules.map((userSemester) => {
          const semesterObj = getSemesterFromUserRecord(userSemester);
          return {
            key: semesterObj.semesterId,
            value: semesterObj.displayName,
          };
        })
      );
    }
  }, []);

  const handleTopicsModalToggle = useCallback((visibility) => {
    setShowTopicsModal(visibility);
  }, []);

  const handleSemesterChange = useCallback(
    ({ key }) => {
      // activate the correct semesters
      setActiveSemesterId(key);
    },
    [setActiveSemesterId]
  );

  const handleDeleteTopic = useCallback(
    (topicName, weekNumber) => {
      removeTopicFromActiveSemester(topicName, weekNumber);
    },
    [removeTopicFromActiveSemester]
  );

  const handleAddTopicClick = useCallback((weekNumber) => {
    setAddTopicWeekNumber(weekNumber);
    setShowTopicsModal(true);
  }, []);

  const handleAddTopic = useCallback(
    (topicName, weekNumber) => {
      addTopicToActiveSemester(topicName, weekNumber);
    },
    [addTopicToActiveSemester]
  );

  const activeSemesterName = getSemesterFromUserRecord(getActiveSemester());

  return (
    <Root>
      <DropdownContainer>
        <Dropdown
          label={"Select Semester"}
          options={dropdownOptions}
          initKey={activeSemesterName?.semesterId}
          onChange={handleSemesterChange}
        />
      </DropdownContainer>
      {showTopicsModal && (
        <AddTopicModal
          userSubject={currentSubject}
          open={showTopicsModal}
          weekNumber={addTopicWeekNumber}
          toggleModal={handleTopicsModalToggle}
          onAddTopic={handleAddTopic}
          customTopics={getActiveSemester().customTopics}
        />
      )}

      {userWeeks.map((week, i) => {
        return (
          <WeekItem
            {...week}
            weekIndex={i}
            key={week.weekNumber}
            onAddTopicClick={handleAddTopicClick}
            onDeleteTopic={handleDeleteTopic}
          />
        );
      })}
      <WeekButtonContainer>
        <AddWeekButton onClick={() => addWeekToSemesterSchedule()}>
          + Add Week
        </AddWeekButton>
        <RemoveWeekButton onClick={() => removeWeekFromSemesterSchedule()}>
          - Remove Week
        </RemoveWeekButton>
      </WeekButtonContainer>
      <SubmitButton onClick={onSubmit}>
        {isNewSemester ? "Submit" : "Update"}
      </SubmitButton>
    </Root>
  );
}

const Root = styled("div")`
  padding-bottom: 1rem;
`;

const DropdownContainer = styled("div")`
  padding: 10px 0 20px 0;
`;

const WeekButtonContainer = styled("div")`
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid white;
`;

const WeekButton = styled(Button)`
  border-radius: 20px;
  padding: 10px;
  width: 100%;
  font-weight: 700;
  background-color: transparent;
  font-size: 1.1rem;
`;

const AddWeekButton = styled(WeekButton)`
  border: 1px solid white;
  color: white;
`;

const RemoveWeekButton = styled(WeekButton)`
  color: white;
  background-color: #c12828;
  margin-top: 10px;
`;

const SubmitButton = styled(WeekButton)`
  background-color: #16c640;
  color: white;
  border: 1px solid #16c640;
  margin-top: 1rem;
  text-transform: uppercase;
`;
