import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import caretSrc from "../../assets/images/caret.png";

export default function Dropdown({ options, onChange, initKey }) {
  const initValue = options.find((option) => option.key === initKey);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initValue ?? null);

  const handleDropdownToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDropdownSelection = useCallback(
    ({ key, value }) => {
      if (onChange) {
        onChange({ key, value });
      }
      setSelectedOption({ key, value });
      setOpen(false);
    },
    [onChange]
  );

  useEffect(() => {
    setSelectedOption(initValue);
  }, [initValue]);

  return (
    <>
      <DropdownContainer onClick={handleDropdownToggle}>
        <Selected>{selectedOption?.value ?? "Select an option"}</Selected>
        <CaretContainer open={open} onClick={handleDropdownToggle}>
          <Caret src={caretSrc} alt="Dropdown Caret" />
        </CaretContainer>
      </DropdownContainer>
      {open && (
        <OptionsContainer>
          <Options>
            {options.map((option) => {
              return (
                <OptionItem
                  title={option.value}
                  key={option.key}
                  active={selectedOption?.key === option.key}
                  onClick={() => {
                    handleDropdownSelection({
                      key: option.key,
                      value: option.value,
                    });
                  }}
                >
                  {option.value}
                </OptionItem>
              );
            })}
          </Options>
        </OptionsContainer>
      )}
    </>
  );
}

const DropdownContainer = styled("div")`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid white;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const Selected = styled("div")`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  color: #333333;
  margin-left: 15px;
  font-size: 1.1rem;
  @media (max-width: 600px) {
    font-size: 0.925rem;
  }
`;

const CaretContainer = styled("div")`
  margin-left: auto;
  margin-right: 15px;
  transition-duration: 0.4s;
  transform: ${(props) => (props.open ? "rotate(0deg);" : "rotate(180deg);")};
`;

const Caret = styled("img")`
  width: 28px;
`;

const OptionsContainer = styled("div")`
  transition-duration: 0.4s;
  background-color: white;
  z-index: 2;
  position: relative;
  height: auto;
  width: 100%;
`;

const Options = styled("ul")`
  position: absolute;
  margin-top: 0;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 20px;
  border-radius: 0 0 7px 7px;
  width: 94%;
  background-color: white;
  color: #333333;
  text-align: left;
  list-style-type: none;
  padding-top: 14px;
  padding-left: 7px;
  padding-right: 6px;
  :last-child {
    padding-bottom: 12px;
  }
  max-height: 250px;
  overflow-y: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const OptionItem = styled("li")`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${(props) => (props.active ? "#333333" : "")};
  color: ${(props) => (props.active ? "white;" : "#333333")};
  padding: 10px 5px;
  padding-left: 18px;
  font-size: 1.1rem;

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;
