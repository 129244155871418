import React, { useState } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import Dropdown from "../shared/Dropdown";
import { getCoursePacings } from "../../api/course-templates";

export default function CoursePacingsDropdown({ onChange }) {
  const [coursePacingOptions, setCoursePacingOptions] = useState();

  useAsyncEffect(async () => {
    const coursePacings = await getCoursePacings();
    setCoursePacingOptions(
      coursePacings.map((coursePacing) => {
        return { key: coursePacing.id, value: coursePacing.attributes.name };
      })
    );
  }, []);
  return (
    <>
      {coursePacingOptions && (
        <Dropdown options={coursePacingOptions} onChange={onChange} />
      )}
    </>
  );
}
