import React from "react";
import warningSrc from "../../assets/images/warning.png";
import styled from "styled-components";

export default function WarningMessage({ message }) {
  return (
    <Root>
      <WarningLogo />
      <Message>{message}</Message>
    </Root>
  );
}

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const WarningLogo = styled("img").attrs({ src: warningSrc })`
  width: 120px;
  height: auto;
  margin-bottom: 1rem;
`;

const Message = styled("div")`
  color: white;
  font-size: 1.4rem;
  text-align: center;
`;
