import React from "react";
import editIconSrc from "../../assets/images/edit-icon.svg";
import styled from "styled-components";

const EditButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <div>
      <EditIcon src={editIconSrc} alt="Edit Icon" />
      <span>UPDATE</span>
    </div>
  </Button>
);

export default EditButton;

const Button = styled("button")`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  color: #3683bb;
  border: none;
  background-color: transparent;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    column-gap: 0.25rem;
  }
`;

const EditIcon = styled("img")`
  width: 20px;
  height: auto;
`;
