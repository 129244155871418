// TODO: Deploy project elsewhere where we can set env variables

// Production
export const APP_ID = "HMqE6dDbYR4QEnKAF4fLm9UGY5x9og3hBzA5ecoc";
export const JS_KEY = "g7kalX8groZVZkBtGfcs7aOlT9Kue68Eiw1spp0x";
export const API_KEY = "XP6XlJOrACgXgkNUeGAl8cVk7wcBySIOeiquRvoe";

// Development
// export const APP_ID = "7Ir33ambjQURoXxorS0BUmqpsWDDFi1zE0eQtDgG";
// export const JS_KEY = "dSgvZZEB6ndJf2MBqbCEYLkXfUuEu0Ezehe37p53";
// export const API_KEY = "tYSdieSvKQhqbSM7pwIwe035bmRdwsEHGjgdEfmr";
