export const MICRO_TOPICS = [
  "Intro to Economics",
  "Trade and Specialization",
  "Supply Demand & Equilibrium",
  "Surplus/Producer Surplus",
  "Elasticity",
  "Price Floors & Price Ceilings",
  "Taxes and Subsidies",
  "Price System",
  "International Trade",
  "Consumer Choice",
  "Costs of Production",
  "Perfect Competition",
  "Monopoly",
  "Price Discrimination",
  "Monopolistic Competition",
  "Game Theory and Oligopoly",
  "Labor Markets",
  "Public Goods",
  "Externalities",
  "Asymmetric Information",
  "Poverty & Inequality",
  "Behavioral Economics",
  "Personal Finance",
];

export const MACRO_TOPICS = [
  "Intro to Economics",
  "Supply Demand & Equilibrium",
  "International Trade",
  "GDP",
  "CPI - Measuring Inflation",
  "Labor/Unemployment",
  "Money & Banking",
  "Long-run Growth",
  "Short-run Fluctuations",
  "Monetary Policy and Inflation",
  "Fiscal Policy",
  "Exchange Rates",
  "Personal Finance",
  "Economic Growth",
  "Government Budget",
];

export const INTRO_TOPICS = [
  "Intro to Economics",
  "Trade and Specialization",
  "Supply Demand & Equilibrium",
  "Elasticity",
  "Price Floors & Price Ceilings",
  "Taxes and Subsidies",
  "International Trade",
  "Consumer Choice",
  "Costs of Production",
  "Perfect Competition",
  "Monopoly",
  "Game Theory and Oligopoly",
  "Labor Markets",
  "Public Goods",
  "Externalities",
  "Poverty & Inequality",
  "GDP",
  "CPI - Measuring Inflation",
  "Labor/Unemployment",
  "Money & Banking",
  "Long-run Growth",
  "Short-run Fluctuations",
  "Monetary Policy and Inflation",
  "Fiscal Policy",
  "Personal Finance",
];
