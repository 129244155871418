import styled from "styled-components";

export default styled("div")`
  font-size: 1.4rem;
  margin-bottom: 0.75rem;
  font-weight: 300;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;
