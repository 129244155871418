import React from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
} from "react-table";
import Button from "./Button";
import BorderlessTable from "./BorderlessTable";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <SearchInput
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
      />
    </span>
  );
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export default function SearchableTable({
  columns,
  data,
  onDownloadSelected,
  onDownloadAll,
  onRowDeletion,
  onRowAddition,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),

          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Render the UI for your table
  return (
    <Root>
      <SearchContainer>
        <div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <SelectInput
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </SelectInput>
        </div>
        <div>
          {typeof onRowAddition === "function" && (
            <AddButton onClick={onRowAddition}>Add</AddButton>
          )}

          <DeleteButton
            disabled={!selectedFlatRows.length}
            onClick={() => {
              onRowDeletion(selectedFlatRows);
            }}
          >
            Delete
          </DeleteButton>
        </div>
      </SearchContainer>

      <TableContainer>
        <ScrollableTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StickyHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </StickyHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </ScrollableTable>
      </TableContainer>

      <PaginationContainer>
        <div>
          <PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </PaginationButton>{" "}
          <PaginationButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </PaginationButton>{" "}
          <PaginationButton onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </PaginationButton>{" "}
          <PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </PaginationButton>{" "}
          <PaginationLabel>
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <PageNumberInput
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
              />
            </span>{" "}
          </PaginationLabel>
        </div>

        <div>
          {typeof onDownloadSelected === "function" && (
            <DownloadButton
              disabled={!selectedFlatRows.length}
              onClick={() => {
                onDownloadSelected(selectedFlatRows);
              }}
            >
              Download Selected ({selectedFlatRows.length})
            </DownloadButton>
          )}
          {typeof onDownloadAll === "function" && (
            <DownloadButton onClick={onDownloadAll}>
              Download All
            </DownloadButton>
          )}
        </div>
      </PaginationContainer>
    </Root>
  );
}

const Root = styled("div")``;

const RowContainer = styled("div")`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SearchContainer = styled(RowContainer)`
  margin-bottom: 5px;
  background-color: #36304a;
  padding: 15px 10px;
  border-radius: 8px;
`;

const SearchInput = styled("input")`
  border: none;
  border-radius: 4px;
  padding: 3px 10px;
  font-size: 0.9rem;
  width: 200px;
  height: 30px;
`;

const PaginationContainer = styled(RowContainer)`
  background-color: #36304a;
  padding: 15px 10px;
  border-radius: 0 0 8px 8px;

  span {
    color: white;
    font-size: 0.9rem;
  }
`;

const PaginationLabel = styled("span")`
  margin: 0 5px;
`;

const PageNumberInput = styled("input")`
  margin-left: 2px;
  border: none;
  border-radius: 4px;
  width: 60px !important;
  padding: 3px 5px;
`;

const SelectInput = styled("select")`
  border: none;
  padding: 2px 0;
  border-radius: 4px;
  padding-right: 3px;
  margin-left: 10px;
  height: 29px;
  width: 90px;
`;

const StickyHeader = styled("th")`
  border: none !important;
  position: sticky;
  top: 0;
  background-color: #36304a;
  z-index: 10;
`;

const TableContainer = styled("div")`
  position: relative;
  overflow: auto;
  height: 560px;
  border-radius: 8px 8px 0 0;

  .pagination {
    padding: 0.5rem;
  }
  .row-select-checkbox {
    margin: 10px;
  }
`;

const ScrollableTable = styled(BorderlessTable)`
  border-radius: 10px;
  
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th,
  td {
    :last-child {
      border-right: 0;
    }

    :nth-child(2) {
      text-align: left;
      width: 130px;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :nth-child(3) {
      text-align: left;
      width: 270px;
      max-width: 270px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :nth-child(4) {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :nth-child(7) {
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    input {
      font-size: 1rem;
    }
  }
`;

const PaginationButton = styled(Button)`
  background-color: white;
  color: #3182bd;
  padding: 3px 10px;
  font-weight: 700;
`;

const DeleteButton = styled(Button)`
  background-color: red;
  font-size: 0.8rem;
  font-weight: 500;
`;

const AddButton = styled(Button)`
  margin-right: 5px;
`;

const DownloadButton = styled(Button)`
  background-color: #3182bd;
  margin-left: 10px;
  font-size: 0.8rem;
  font-weight: 500;
`;
