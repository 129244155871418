import React, { useState, createContext, useCallback } from "react";
import { getUserSemesters, getSemesters } from "../helpers/back4app";
import mapOrder from "../utils/mapOrder";
import {
  generateSemesterWeeks,
  generateUpcomingWeek,
} from "../utils/semesterDates";
import SUBJECTS from "../constants/subjects";
import moment from "moment";
import CUSTOM_TOPICS from "../constants/customTopics";

export const UserContext = createContext();

function useProvideUser() {
  const [email, setEmail] = useState("");
  const [activeSemesterId, setActiveSemesterId] = useState();
  const [semesterSchedules, setSemesterSchedules] = useState([]);

  const semesterIdMatchesActiveId = useCallback(
    (semester) => {
      return (
        semester.semesterId === activeSemesterId ||
        semester.semester === activeSemesterId
      );
    },
    [activeSemesterId]
  );

  const addTopicToActiveSemester = useCallback(
    (topicName, weekNumber) => {
      setSemesterSchedules((prevSemesters) =>
        prevSemesters.map((semester) => {
          return semesterIdMatchesActiveId(semester)
            ? {
                ...semester,
                weeks: semester.weeks.map((week) => {
                  return weekNumber === week.numberOfWeek
                    ? {
                        ...week,
                        topic: !week.topic.includes(topicName)
                          ? [...week.topic, topicName]
                          : week.topic,
                      }
                    : week;
                }),
              }
            : semester;
        })
      );
    },
    [semesterIdMatchesActiveId]
  );

  const addCustomTopicToActiveSemester = useCallback(
    (topicName) => {
      setSemesterSchedules((prevSemesters) =>
        prevSemesters.map((semester) => {
          return semesterIdMatchesActiveId(semester)
            ? {
                ...semester,
                customTopics: !semester.customTopics.includes(topicName)
                  ? [...semester.customTopics, topicName]
                  : semester.customTopics,
              }
            : semester;
        })
      );
    },
    [semesterIdMatchesActiveId]
  );

  const removeTopicFromActiveSemester = useCallback(
    (topicName, weekNumber) => {
      setSemesterSchedules((prevSemesters) =>
        prevSemesters.map((semester) => {
          return semesterIdMatchesActiveId(semester)
            ? {
                ...semester,
                weeks: semester.weeks.map((week) => {
                  return weekNumber === week.numberOfWeek
                    ? {
                        ...week,
                        topic: week.topic.filter(
                          (topic) => topic !== topicName
                        ),
                      }
                    : week;
                }),
              }
            : semester;
        })
      );
    },
    [semesterIdMatchesActiveId]
  );

  const removeWeekFromSemesterSchedule = useCallback(() => {
    setSemesterSchedules((prevSemesters) =>
      prevSemesters.map((semester) => {
        return semesterIdMatchesActiveId(semester)
          ? {
              ...semester,
              weeks:
                semester.weeks.length > 1
                  ? semester.weeks.slice(0, -1)
                  : semester.weeks,
            }
          : semester;
      })
    );
  }, [semesterIdMatchesActiveId]);

  const addWeekToSemesterSchedule = useCallback(() => {
    setSemesterSchedules((prevSemesters) =>
      prevSemesters.map((semester) => {
        if (semesterIdMatchesActiveId(semester)) {
          const latestDate = semester.weeks[semester.weeks.length - 1].date;
          const newWeek = generateUpcomingWeek(latestDate);
          return { ...semester, weeks: [...semester.weeks, newWeek] };
        } else {
          return semester;
        }
      })
    );
  }, [semesterIdMatchesActiveId]);

  const getActiveSemester = useCallback(() => {
    return semesterSchedules.find((semester) =>
      semesterIdMatchesActiveId(semester)
    );
  }, [semesterSchedules, semesterIdMatchesActiveId]);

  const createNewSemester = useCallback(
    (urlParams, startDate) => {
      const weeks = generateSemesterWeeks(startDate, 12);

      setSemesterSchedules((prevSemesters) => [
        ...prevSemesters,
        generateSemester({ ...urlParams, weeks, startDate }),
      ]);
      setActiveSemesterId(urlParams.semester);
    },
    [setActiveSemesterId]
  );

  const copyPreviousSemester = useCallback(
    (urlParams, startDate) => {
      setSemesterSchedules((prevSemesters) => {
        const latestSemesterIndex = prevSemesters.length - 1;
        const previousSemester = prevSemesters[latestSemesterIndex];
        const previousSemesterWeekCount = previousSemester.weeks.length;
        const newWeeks = generateSemesterWeeks(
          startDate,
          previousSemesterWeekCount
        );

        const copiedWeeks = newWeeks.map((weekObj, index) => {
          return { ...weekObj, topic: previousSemester.weeks[index].topic };
        });

        return [
          ...prevSemesters,
          generateSemester({
            ...urlParams,
            startDate,
            weeks: copiedWeeks,
            customTopics: previousSemester.customTopics,
          }),
        ];
      });

      setActiveSemesterId(urlParams.semester);
    },
    [setActiveSemesterId]
  );

  const loadUserSemesters = useCallback(async (email) => {
    const userSemesters = await getUserSemesters(email);
    const semesterList = await getSemesters();

    const semesterOrder = semesterList.map(
      (semester) => semester.attributes.semesterId
    );

    // Order user semesters
    setSemesterSchedules(
      mapOrder(
        userSemesters
          .filter((semester) => semester.attributes.semesterId)
          .map((semester) => {
            return { id: semester.id, ...semester.attributes };
          }),
        semesterOrder,
        "semesterId"
      )
    );
  }, []);

  const activateLatestSemester = useCallback(() => {
    const latestIndex = semesterSchedules.length - 1;
    setActiveSemesterId(
      semesterSchedules[latestIndex].semesterId ??
        semesterSchedules[latestIndex].semester
    );
  }, [semesterSchedules]);

  const setUserEmail = useCallback((emailInput) => {
    setEmail(emailInput);
  }, []);

  const generateSemester = ({
    exid,
    teaching,
    email,
    semester,
    weeks,
    startDate,
    customTopics,
  }) => {
    return {
      isUpdate: false,
      userId: exid,
      subject: SUBJECTS.find(
        (subject) => subject.toLowerCase() === teaching.toLowerCase()
      ),
      microAndMacro: false,
      email: email,
      startDate: moment(startDate).format("YYYY/M/D") ?? "",
      semesterId: semester,
      weeks: weeks ?? [],
      customTopics: customTopics ?? CUSTOM_TOPICS,
    };
  };

  return {
    email,
    setUserEmail,
    activeSemesterId,
    semesterSchedules,
    createNewSemester,
    setActiveSemesterId,
    getActiveSemester,
    loadUserSemesters,
    copyPreviousSemester,
    addTopicToActiveSemester,
    setSemesterSchedules,
    removeTopicFromActiveSemester,
    addCustomTopicToActiveSemester,
    removeWeekFromSemesterSchedule,
    addWeekToSemesterSchedule,
    activateLatestSemester,
  };
}

export const UserProvider = ({ children }) => {
  const user = useProvideUser();
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
