import React, { useState } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import { EMAIL, SEMESTER } from "../../../constants/scheduleBuilderUrlParams";
import getUrlParams from "../../../utils/getUrlParams";
import useUser from "../../../hooks/useUser";
import { isMobile } from "../../../utils/screen";
import MobileScheduler from "../../scheduler/mobileScheduler/MobileScheduler";
import SemesterTabManager from "../../scheduler/SemesterTabManager";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import Swal from "sweetalert2";

const ScheduleUpdatePage = () => {
  const [loading, setLoading] = useState(false);
  const [semesterId, setSemesterId] = useState();
  const userContext = useUser();

  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      setLoading(true);
      try {
        const { semesterSchedules } = userContext;
        const { email, semester } = await getUrlParams([EMAIL, SEMESTER]);

        if (semesterSchedules.length === 0) {
          await userContext.loadUserSemesters(email);
        }
        setSemesterId(semester);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: error,
        });
      } finally {
        setLoading(false);
      }
    }
  }, []);

  return (
    <>
      <SpinnerOverlay loading={loading} />

      {!loading && semesterId && userContext.semesterSchedules.length > 0 && (
        <>
          {isMobile() ? (
            <MobileScheduler
              updateOnly
              activeSemesterIdDuringUpdate={semesterId}
            />
          ) : (
            <SemesterTabManager
              updateOnly
              activeSemesterIdDuringUpdate={semesterId}
            />
          )}
        </>
      )}
    </>
  );
};

export default ScheduleUpdatePage;
