import moment from "moment";

export function sortCheckbox(row1, row2, columnId) {
  let value1 = row1.original[columnId];

  if (value1) {
    return -1;
  }

  return 1;
}

export function sortDate(row1, row2, columnId) {
  let value1 = row1.original[columnId];
  let value2 = row2.original[columnId];

  const date1 = moment(value1);
  const date2 = moment(value2);

  if (date1.isSameOrAfter(date2)) {
    return -1;
  }

  return 1;
}
