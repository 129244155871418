import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ACCOUNT_DASHBOARD_ROUTE } from "../../constants/routes/accountRoutes";

export default function SemesterSubmissionConfirmation({
  children,
  showAccountLink,
}) {
  return (
    <Root>
      <div>{children}</div>
      {showAccountLink && (
        <AccountLink to={ACCOUNT_DASHBOARD_ROUTE}>My Account</AccountLink>
      )}
    </Root>
  );
}

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  font-size: 1.125rem;
  color: white;
  max-width: 580px;
  width: 100%;

  .link-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;
    margin-top: 1rem;
    color: white;
    border: 2px solid white;
    text-decoration: none;
    height: 40px;
    max-width: 200px;
    margin: 0 auto;
  }
`;

const AccountLink = styled(Link)`
  display: block;
  color: #75cfff;
  margin-top: 1.5rem;
`;
