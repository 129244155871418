import styled from "styled-components";

export default styled("div")`
  border: 1px solid black;
  border-radius: 4px;
  background-color: white;
  padding: 0.5rem 1rem;
  cursor: move;
  width: 243px;
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
  position: relative;
`;
