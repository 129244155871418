export default function getClosestWeekWithTopicsStartIndex(weeksArray) {
  if (!weeksArray[0].topic.length) {
    for (let i = 0; i < weeksArray.length; i++) {
      if (weeksArray[i].topic.length > 0) {
        return i;
      }
    }
  } else {
    return 0;
  }
}
