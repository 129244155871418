import React, { useCallback, useState } from "react";
import EconInboxLogo from "../shared/EconInboxLogo";
import Button from "../shared/Button";
import DatePickerInput from "../shared/DatePickerInput";
import styled from "styled-components";
import Swal from "sweetalert2";

export default function StartDateSelectionOverlay({ onSubmit, transition }) {
  const [startDate, setStartDate] = useState();

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!startDate) {
        Swal.fire({
          icon: "warning",
          text: `Please fill out all fields.`,
          width: 400,
        });
      } else {
        onSubmit(startDate);
      }
    },
    [onSubmit, startDate]
  );

  const submitLabel =
    transition === "new" ? "Create Schedule" : "Review Schedule";

  return (
    <Root>
      <EconInboxLogo />
      <Form onSubmit={handleSubmit}>
        <label>Your course start date</label>
        <DatePickerInput
          placeholderText="Select date"
          onChange={handleStartDateChange}
        />
        <SubmitButton type="submit">{submitLabel}</SubmitButton>
      </Form>
    </Root>
  );
}

const Root = styled("div")`
  margin: 0 auto;
  text-align: center;
  width: 450px;
  label {
    display: block;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 450px) {
    padding: 0 0.75rem;
    width: 100%;
    label {
      font-size: 1.2rem;
    }
  }
`;

const Form = styled("form")`
  margin-top: 0.75rem;
  flex: 1;
`;

const SubmitButton = styled(Button)`
  font-size: 1.25rem;
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  border-radius: 3px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
`;
