/* Using two keys to handle old semesters with no Back4App semester record */

export default function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    let A = a[key];
    let B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}
