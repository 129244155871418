import React from "react";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import useAccountDashboard from "../../hooks/useAccountDashboard";
import Button from "../shared/Button";
import styled from "styled-components";
import BorderlessTable from "../shared/BorderlessTable";
import EditButton from "../shared/EditButton";
import formatUserScheduleDateRange from "./utils/formatUserScheduleDateRange";
import Swal from "sweetalert2";

export const AccountDashboardItems = ({
  userSemesters,
  appSemestersContext,
}) => {
  const {
    secondSemesterCourse,
    newSemester,
    currentSemesterUpdate,
    futureSemesterUpdates,
    previousSemesterUpdate,
  } = useAccountDashboard({ userSemesters, appSemestersContext });

  const { getSemesterBySemesterId } = appSemestersContext;
  const history = useHistory();
  const navigateToUrl = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  return (
    <Root>
      <TableContainer>
        <BorderlessTable>
          {previousSemesterUpdate && (
            <tr>
              <td>
                {
                  getSemesterBySemesterId(
                    previousSemesterUpdate.semester.semesterId
                  ).displayName
                }
              </td>
              <td>
                {`${previousSemesterUpdate.semester.subject} 
                (${formatUserScheduleDateRange(
                  previousSemesterUpdate.semester
                )})`}
              </td>
              <td>
                <EditButton
                  onClick={() => navigateToUrl(previousSemesterUpdate.url)}
                />
              </td>
              <td></td>
            </tr>
          )}
          {currentSemesterUpdate && (
            <tr>
              <td>
                {
                  getSemesterBySemesterId(
                    currentSemesterUpdate.semester.semesterId
                  ).displayName
                }
              </td>
              <td>
                {`${currentSemesterUpdate.semester.subject} 
                (${formatUserScheduleDateRange(
                  currentSemesterUpdate.semester
                )})`}
              </td>
              <td>
                <EditButton
                  onClick={() => navigateToUrl(currentSemesterUpdate.url)}
                />
              </td>
              <td>
                {secondSemesterCourse && (
                  <SecondCourseButton
                    onClick={() =>
                      Swal.fire({
                        icon: "warning",
                        text: "We're working on this but it's not quite ready! As a workaround, you can add additional topics to your current course schedule.",
                        width: 425,
                      })
                    }
                  >
                    Add a 2<sup>nd</sup> Course
                  </SecondCourseButton>
                )}
              </td>
            </tr>
          )}
          {futureSemesterUpdates &&
            futureSemesterUpdates.map((futureSemester) => (
              <tr key={futureSemester.semester.semesterId}>
                <td>
                  {
                    getSemesterBySemesterId(futureSemester.semester.semesterId)
                      .displayName
                  }
                </td>
                <td>
                  {`${futureSemester.semester.subject} 
                  (${formatUserScheduleDateRange(futureSemester.semester)})`}
                </td>
                <td>
                  <EditButton
                    onClick={() => navigateToUrl(futureSemester.url)}
                  />
                </td>
                <td></td>
              </tr>
            ))}
        </BorderlessTable>
      </TableContainer>
      {newSemester && (
        <ButtonLink href={newSemester.url}>
          Sign-up for a New Semester
        </ButtonLink>
      )}
    </Root>
  );
};

export default AccountDashboardItems;

const ButtonLink = styled("a")`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  color: white;
  background: #16c640;
  border-radius: 4px;
  width: 260px !important;
  font-weight: bold;
`;

const SecondCourseButton = styled("button")`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #3683bb;
  font-weight: 600;
  text-transform: uppercase;

  sup {
    font-size: 0.5rem;
  }
`;

const Root = styled("div")`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  ${Button}, a {
    text-align: left;
    width: 400px;
    max-width: 100%;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    font-size: 0.9rem;
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TableContainer = styled("div")`
  position: relative;
  overflow: auto;
  margin-bottom: 2rem;
`;
