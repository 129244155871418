// Topics
export const EXAM = "Exam";
export const LAST_CLASS = "Last Class";
export const SKIP_NO_CLASS = "Skip/No Class";
export const CUSTOM_TOPIC = "Custom Topic";

// Emails and admin flags
export const DUPLICATE_TOPIC_EMAIL = "Duplicate Topic Email - 1";
export const LAST_SCHEDULED_EMAIL = "**LAST Scheduled EMAIL**";
export const MULTIPLE_EMAIL_NOTIFICATION = "Multiple Email Notification";
export const MID_SEMESTER_FEEDBACK_EMAIL = "Mid-Semester Feedback Email";
export const FINAL_FEEDBACK_EMAIL = "Feedback Email (Final)";

// Breaks
export const WEEK_BREAK = "Week Break";
