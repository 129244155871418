import parse from "../parse";
import { CoursesClass, CourseHolidaysClass } from "../../constants/classes";

export default async function getUpcomingCourseHolidaysForCourse({
  courseId,
  date,
}) {
  const Course = parse.Object.extend(CoursesClass);
  const CourseHolidays = parse.Object.extend(CourseHolidaysClass);
  const query = new parse.Query(CourseHolidays);

  query.equalTo("course", new Course({ id: courseId }));
  query.greaterThanOrEqualTo("date", date);

  return await query.find();
}
