export default function holidayMappings(weeks, holidays) {
  return weeks.map((week) => {
    if (week) {
      return week.map((topic) => {
        return holidays.includes(topic) ? "" : topic;
      });
    }
    return week;
  });
}
