import React, { useState, useCallback, forwardRef } from "react";
import DatePicker from "react-datepicker";
import calendarLogoSrc from "../../assets/images/calendar.png";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const DatePickerInput = ({ onChange, placeholderText, ...props }) => {
  const [selectedDate, setSelectedDate] = useState();

  const handleSelectedDateChange = useCallback(
    (date) => {
      setSelectedDate(date);
      onChange(date);
    },
    [onChange]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <CalendarButton className="calender-w-icon" onClick={onClick} ref={ref}>
      {value === "" ? placeholderText : value}
      <CalendarLogo />
    </CalendarButton>
  ));

  return (
    <Root>
      <DatePicker
        onChange={handleSelectedDateChange}
        selected={selectedDate}
        customInput={<ExampleCustomInput />}
        style={{ border: "1px solid white" }}
        {...props}
      />
    </Root>
  );
};

export default DatePickerInput;
const Root = styled("div")`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;
const CalendarButton = styled("div")`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 8px;
  background-color: #fafafa;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  font-size: 1.1rem;

  @media (max-width: 600px) {
    width: 100%;
    font-size: 0.925rem;
  }
`;

const CalendarLogo = styled("img").attrs({
  src: calendarLogoSrc,
  alt: "Calendar Logo",
})`
  height: 20px;
  width: auto;
`;
