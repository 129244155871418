import React, { useCallback } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import Button from "../shared/Button";
import "react-circular-progressbar/dist/styles.css";
import "./CompletionTracker.css";
import styled from "styled-components";
import Swal from "sweetalert2";

const REQUIRED_WEEK_COUNT = 4;

export default function CompletionTrackerSubmit({
  percentage,
  weeks,
  onSubmit,
  isNew,
  buttonOnly,
}) {
  const getFilledWeeks = useCallback(() => {
    return weeks ? weeks.filter((week) => week.topic.length).length : 0;
  }, [weeks]);

  const handleButtonOnlySubmit = useCallback(() => {
    const filledWeeks = getFilledWeeks();
    if (filledWeeks < REQUIRED_WEEK_COUNT) {
      const requiredWeekCount = REQUIRED_WEEK_COUNT - filledWeeks;
      Swal.fire({
        icon: "warning",
        text: `You need to fill out ${requiredWeekCount} more ${
          requiredWeekCount === 1 ? "week" : "weeks"
        }.`,
        width: 400,
      });
    } else {
      onSubmit();
    }
  }, [getFilledWeeks, onSubmit]);

  const buttonLabel = isNew ? "Submit Schedule" : "Update Schedule";

  return (
    <div className="completionTrackerComponent">
      {!buttonOnly && (
        <>
          <CircularProgressbar
            strokeWidth={14}
            value={percentage}
            text={`${percentage}%`}
          />
          {getFilledWeeks() >= 4 && percentage > 50 && percentage < 100 ? (
            <>
              <div className="completionMessageWrapper">
                <div>
                  More than
                  <br />
                  halfway there!
                </div>
                <div>
                  (It's ok to submit an
                  <br />
                  incomplete schedule - you
                  <br />
                  can finish later.)
                </div>
              </div>
            </>
          ) : null}
          {getFilledWeeks() >= 4 && percentage === 100 ? (
            <>
              <div className="completionMessageWrapper">
                <div>
                  All done!
                  <br />
                  Enjoy EconInbox!
                </div>
              </div>
            </>
          ) : null}
        </>
      )}

      {getFilledWeeks() >= REQUIRED_WEEK_COUNT && !buttonOnly && (
        <SubmitButton onClick={onSubmit}>{buttonLabel}</SubmitButton>
      )}
      {buttonOnly && (
        <SubmitButton onClick={handleButtonOnlySubmit} buttonOnly={buttonOnly}>
          {buttonLabel}
        </SubmitButton>
      )}
    </div>
  );
}

const SubmitButton = styled(Button)`
  width: ${(props) => (props.buttonOnly ? "243px" : "100%")};
  padding: 10px;
  margin-top: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;
