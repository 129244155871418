import { semicolonJoin } from "../joinAndSplit";
import {
  EXAM,
  LAST_CLASS,
  SKIP_NO_CLASS,
  CUSTOM_TOPIC,
} from "../../constants/topics";

export default function customTopicMappings(weeks, userCustomTopics) {
  let customTopics = [];

  const weeksWithCustomTopicMappings = weeks.map((topicList, weekNumber) => {
    if (topicList) {
      const refinedTopicList = topicList
        .map((topic) => {
          if (
            topic === EXAM ||
            topic === LAST_CLASS ||
            topic === SKIP_NO_CLASS
          ) {
            return topic;
          }

          if (userCustomTopics.includes(topic)) {
            customTopics.push(`Week ${weekNumber + 1}: ${topic}`);
            return topicList.length === 1 ? CUSTOM_TOPIC : "";
          }
          return topic;
        })
        .filter((topic) => topic !== SKIP_NO_CLASS);

      return Array.from(new Set(refinedTopicList));
    } else {
      return topicList;
    }
  });

  return {
    weeks: weeksWithCustomTopicMappings,
    customTopics: semicolonJoin(customTopics),
  };
}
