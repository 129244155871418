import React, { useCallback, useState } from "react";
import CopySchedulerFormLabel from "./CopySchedulerFormLabel";
import CopySchedulerForm from "./CopySchedulerForm";
import Dropdown from "../../shared/Dropdown";
import useUser from "../../../hooks/useUser";
import useSemester from "../../../hooks/useSemester";
import EconInboxLogo from "../../shared/EconInboxLogo";
import Button from "../../shared/Button";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import hasMicroAndMacroTopics from "../../../utils/hasMicroAndMacroTopics";
import { saveUserAsync } from "../../../helpers/back4app";
import { generateSemesterWeeks } from "../../../utils/semesterDates";
import styled from "styled-components";
import moment from "moment";
import getClosestWeekWithTopicsEndIndex from "../helper/getClosestWeekWithTopicsEndIndex";
import getClosestWeekWithTopicsStartIndex from "../helper/getClosestWeekWithTopicsStartIndex";
import CopySemesterSubmissionConfirmation from "../../confirmation/CopySemesterSubmissionConfirmation";
import Swal from "sweetalert2";

export default function CopyScheduler({ userSemesterSchedules, urlParams }) {
  const [loading, setLoading] = useState(false);
  const [semesterIdSigningUpFor, setSelectedSemesterIdSigningUpFor] =
    useState();
  const { semesters } = useSemester();
  const { setSemesterSchedules } = useUser();
  const [didSubmitSchedule, setDidSubmitSchedule] = useState(false);

  const handleNewUserSemesterDropdownChange = useCallback((selectedOption) => {
    setSelectedSemesterIdSigningUpFor(selectedOption.key);
  }, []);

  const upcomingSemesterDropdownOptions = semesters
    .filter((semester) => {
      // Get active and upcoming semesters
      const today = new Date();
      const semesterEndDate = new Date(semester.endDate);
      return semesterEndDate.getTime() > today.getTime();
    })
    .map((semester) => {
      const upcomingSemesterRecordExists = userSemesterSchedules.find(
        (userSemester) => userSemester.semesterId === semester.semesterId
      );

      return {
        key: semester.semesterId,
        value: upcomingSemesterRecordExists
          ? `${semester.displayName} (Enrolled)`
          : semester.displayName,
      };
    });

  const enrolledInUpcomingSemester = userSemesterSchedules.find(
    (semester) => semester.semesterId === semesterIdSigningUpFor
  );

  const handleCopySubmission = useCallback(
    async ({ courseStartDate, semesterReusingId }) => {
      setLoading(true);
      // Perform copy operation and then redirect to success page
      const semesterReusing = userSemesterSchedules.find(
        (schedule) =>
          schedule.semesterId === semesterReusingId ||
          schedule.semester === semesterReusingId
      );

      let weeksReusing = semesterReusing.weeks;

      // Delete trailing weeks with empty topics
      if (!weeksReusing[weeksReusing.length - 1].topic.length) {
        const latestWeekWithTopicsIndex =
          getClosestWeekWithTopicsEndIndex(weeksReusing);
        weeksReusing = weeksReusing.slice(0, latestWeekWithTopicsIndex + 1);
      }

      // Delete empty weeks at the beginning
      if (!weeksReusing[0].topic.length) {
        const latestWeekWithTopicsIndex =
          getClosestWeekWithTopicsStartIndex(weeksReusing);

        weeksReusing = weeksReusing.slice(
          latestWeekWithTopicsIndex,
          weeksReusing.length
        );
      }

      // Generate new weeks
      const newWeeks = generateSemesterWeeks(
        courseStartDate,
        weeksReusing.length
      ).map((weekObj, index) => {
        // Copy previous topics
        return { ...weekObj, topic: weeksReusing[index].topic };
      });

      const microAndMacro = hasMicroAndMacroTopics({
        subject: semesterReusing.subject,
        weeks: weeksReusing,
      });

      const newSemester = {
        email: semesterReusing.email,
        subject: semesterReusing.subject,
        weeks: newWeeks,
        semesterId: semesterIdSigningUpFor,
        userId: urlParams.exid,
        startDate: moment(courseStartDate).format("YYYY/M/D") ?? "",
        customTopics: semesterReusing.customTopics,
        isUpdate: false,
        processed: false,
        processedUpdate: false,
        updated: false,
        microAndMacro,
      };

      await saveUserAsync(newSemester);
      setSemesterSchedules((prevSemesters) => [...prevSemesters, newSemester]);
      setLoading(false);
      setDidSubmitSchedule(true);
    },
    [
      semesterIdSigningUpFor,
      setSemesterSchedules,
      urlParams.exid,
      userSemesterSchedules,
    ]
  );

  return (
    <Root>
      <SpinnerOverlay loading={loading} />
      {!didSubmitSchedule && !loading ? (
        <>
          <EconInboxLogo />
          <CopySchedulerFormLabel>
            Semester you want to sign up for
          </CopySchedulerFormLabel>
          {upcomingSemesterDropdownOptions && (
            <Dropdown
              options={upcomingSemesterDropdownOptions}
              onChange={handleNewUserSemesterDropdownChange}
            />
          )}
          {semesterIdSigningUpFor && enrolledInUpcomingSemester && (
            <UpdateButton
              onClick={() =>
                Swal.fire({
                  icon: "warning",
                  text: "You are already enrolled in this semester. Choose another semester.",
                  width: 375,
                })
              }
            >
              Update
            </UpdateButton>
          )}
          {semesterIdSigningUpFor && !enrolledInUpcomingSemester && (
            <CopySchedulerForm
              userSemesterSchedules={userSemesterSchedules}
              semesterIdSigningUpFor={semesterIdSigningUpFor}
              onSubmit={handleCopySubmission}
            />
          )}
        </>
      ) : (
        <SubmissionConfirmationContainer>
          <CopySemesterSubmissionConfirmation
            showAccountLink
            userEmail={urlParams?.email}
            semesterId={semesterIdSigningUpFor}
          />
        </SubmissionConfirmationContainer>
      )}
    </Root>
  );
}

const UpdateButton = styled(Button)`
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  margin-top: 1rem;
`;

const Root = styled("div")`
  padding: 10px 5px;
  width: 475px;
  height: 100vh;
  text-align: center;

  ${EconInboxLogo} {
    margin-bottom: 1rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }
`;

const SubmissionConfirmationContainer = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
