import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import SearchableTable from "../../shared/SearchableTable";
import { getUsers, updateUser, deleteUser } from "../../../helpers/back4app";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import Modal from "../../shared/Modal";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import { sortCheckbox, sortDate } from "./utils/sortBy";
import { generateCSVString, downloadCSV, generateCSVArray } from "./utils/csv";
import { CSV_HEADER } from "./constants/csvHeader";

const UserTable = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState();
  const [usersToDelete, setUsersToDelete] = useState([]);

  const handleDownloadSelected = async (selectedRows) => {
    const csvStr = generateCSVString(
      CSV_HEADER,
      await generateCSVArray(selectedRows.map((row) => row.original))
    );

    downloadCSV(csvStr);
  };

  const handleDownloadAll = useCallback(async () => {
    const csvStr = generateCSVString(CSV_HEADER, await generateCSVArray(users));
    downloadCSV(csvStr);
  }, [users]);

  const handleUpdatedCheck = async (e, userRow) => {
    const checkValue = e.target.checked;
    await updateUser(userRow.id, { updated: checkValue });
  };

  const handleProcessedUpdateCheck = async (e, userRow) => {
    const checkValue = e.target.checked;
    await updateUser(userRow.id, { processedUpdate: checkValue });
  };

  const handleProcessedCheck = async (e, userRow) => {
    const checkValue = e.target.checked;
    await updateUser(userRow.id, { processed: checkValue });
  };

  const handleRowDeletion = useCallback(async () => {
    setModalOpen(false);
    setLoading(true);

    await Promise.all(
      usersToDelete.map(async (row) => {
        const objectId = row.original.id;
        return await deleteUser(objectId);
      })
    );

    const selectedIds = usersToDelete.map((user) => user.original.id);

    setUsers((users) => {
      return users.filter((user) => !selectedIds.includes(user.id));
    });
    setUsersToDelete([]);
    setLoading(false);
  }, [usersToDelete]);

  const openDeletionModal = async (selectedRows) => {
    setUsersToDelete(selectedRows);
    setModalOpen(true);
  };

  const handleModalToggle = (open) => {
    if (!open) {
      setUsersToDelete(null);
    }
    setModalOpen(open);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Exid",
        accessor: "userId",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Semester",
        accessor: "semester",
      },
      {
        Header: "Semester UTM",
        accessor: "semesterId",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ row }) => {
          return (
            <span>{moment(row.original.startDate).format("MM/DD/yyyy")}</span>
          );
        },
        sortType: sortDate,
      },
      {
        Header: "Submitted",
        accessor: "createdAt",
        Cell: ({ row }) => {
          return <span>{moment(row.original.createdAt).fromNow()}</span>;
        },
        sortType: sortDate,
      },
      {
        Header: "Updated Date",
        accessor: "userUpdatedAt",
        Cell: ({ row }) => {
          return (
            <span>
              {row.original.userUpdatedAt
                ? moment(row.original.userUpdatedAt).fromNow()
                : "Never"}
            </span>
          );
        },
        sortType: sortDate,
      },
      {
        Header: "Updated",
        accessor: "updated",
        Cell: ({ row }) => {
          return (
            <span>
              <input
                defaultChecked={row.original.updated}
                onChange={(event) => handleUpdatedCheck(event, row.original)}
                type="checkbox"
              />
            </span>
          );
        },
        sortType: sortCheckbox,
      },
      {
        Header: "Processed Update",
        accessor: "processedUpdate",
        Cell: ({ row }) => {
          return (
            <span>
              <input
                defaultChecked={row.original.processedUpdate}
                onChange={(event) =>
                  handleProcessedUpdateCheck(event, row.original)
                }
                type="checkbox"
              />
            </span>
          );
        },
        sortType: sortCheckbox,
      },

      {
        Header: "Processed",
        accessor: "processed",
        Cell: ({ row }) => {
          return (
            <span>
              <input
                defaultChecked={row.original.processed}
                onChange={(event) => handleProcessedCheck(event, row.original)}
                type="checkbox"
              />
            </span>
          );
        },
        sortType: sortCheckbox,
      },
    ],
    []
  );

  useEffect(() => {
    (async function () {
      setLoading(true);
      try {
        const users = await getUsers();
        setUsers(
          JSON.parse(
            JSON.stringify(
              users.map((userData) => {
                return { ...userData.attributes, id: userData.id };
              })
            )
          )
        );
        setLoading(false);
      } catch (e) {
        alert("Unable to load users. Please try again later.");
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <SpinnerOverlay loading={loading} />
      {usersToDelete && (
        <Modal
          header="Delete User(s)"
          open={modalOpen}
          toggleModal={handleModalToggle}
          onDelete={handleRowDeletion}
        >
          <DeleteConfirmation
            onDelete={handleRowDeletion}
            items={usersToDelete.map((users) => users.original.email)}
          />
        </Modal>
      )}
      {users && (
        <SearchableTable
          columns={columns}
          data={users}
          onDownloadSelected={handleDownloadSelected}
          onDownloadAll={handleDownloadAll}
          onRowDeletion={openDeletionModal}
        />
      )}
    </>
  );
};

export default UserTable;
