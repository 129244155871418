import React from "react";
import Button from "../shared/Button";
import tutorialGifSrc from "../../assets/images/tutorial.gif";

import styled from "styled-components";

export default function DragAndDropTutorial({ onStopTutorial }) {
  return (
    <TutorialContainer onClick={onStopTutorial}>
      <TutorialHeader>How it Works</TutorialHeader>
      <TutorialText>
        Click and drag a topic from the right-hand side to a desired week slot
        on the left.
      </TutorialText>
      <Tutorial />
      <ReadyButton onClick={onStopTutorial}>Build My Schedule</ReadyButton>
    </TutorialContainer>
  );
}

const TutorialContainer = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const TutorialText = styled("p")`
  font-size: 16px;
  font-weight: 600;
  width: 60%;
  text-align: center;
  color: #fafafa;
`;

const TutorialHeader = styled("div")`
  font-size: 24px;
  font-weight: 700;
`;

const Tutorial = styled("img").attrs({
  src: tutorialGifSrc,
  alt: "Application Tutorial Gif",
})`
  width: 800px;
  height: auto;
`;

const ReadyButton = styled(Button)`
  margin-top: 2rem;
  width: 245px;
  height: 40px;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
`;
