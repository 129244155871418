import React, { useState, useCallback } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import moment from "moment";
import DeleteConfirmation from "../../shared/DeleteConfirmation";
import Modal from "../../shared/Modal";
import SearchableTable from "../../shared/SearchableTable";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import SemesterForm from "./SemesterForm";
import { sortDate } from "../users/utils/sortBy";
import {
  getSemesters,
  deleteSemester,
  updateSemester,
  addSemester,
} from "../../../helpers/back4app";
import Button from "../../shared/Button";
import styled from "styled-components";

export default function Semester() {
  const [semesters, setSemesters] = useState();
  const [formModalVisibility, setModalFormVisibility] = useState(false);
  const [deletionModalVisibility, setModalDeletionVisibility] = useState(false);
  const [selectedForUpdate, setSelectedForUpdate] = useState();
  const [selectedForDeletion, setSelectedForDeletion] = useState();
  const [loading, setLoading] = useState();

  const openSemesterFormModal = async (semester) => {
    if (semester) {
      setSelectedForUpdate(semester);
    }

    setModalFormVisibility(true);
  };

  const handleSemesterFormModalToggle = (open) => {
    if (!open) {
      setSelectedForUpdate(null);
    }
    setModalFormVisibility(open);
  };

  const handleDeleteModalToggle = (open) => {
    if (!open) {
      setSelectedForDeletion(null);
    }
    setModalDeletionVisibility(open);
  };

  const openSemesterDeletionModal = async (selectedRows) => {
    setSelectedForDeletion(selectedRows);
    setModalDeletionVisibility(true);
  };

  const handleRowDeletion = useCallback(async () => {
    setModalDeletionVisibility(false);
    setLoading(true);

    await Promise.all(
      selectedForDeletion.map(async (row) => {
        const semesterId = row.original.semesterId;
        return await deleteSemester(semesterId);
      })
    );

    const selectedIds = selectedForDeletion.map(
      (semester) => semester.original.semesterId
    );

    setSemesters((semesters) => {
      return semesters.filter(
        (semester) => !selectedIds.includes(semester.semesterId)
      );
    });

    setSelectedForDeletion([]);
    setLoading(false);
  }, [selectedForDeletion]);

  const handleSemesterSubmit = useCallback(
    async (semesterData) => {
      setLoading(true);
      setModalFormVisibility(false);

      if (selectedForUpdate) {
        const semesterId = selectedForUpdate.semesterId;
        await updateSemester(semesterId, semesterData);
        setSemesters((semesters) => {
          return semesters.map((semester) => {
            return semester.semesterId === semesterData.semesterId
              ? semesterData
              : semester;
          });
        });
      } else {
        await addSemester(semesterData);
        setSemesters((semesters) => [...semesters, semesterData]);
      }

      setLoading(false);
      setSelectedForUpdate(null);
    },
    [selectedForUpdate]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Semester ID",
        accessor: "semesterId",
      },
      {
        Header: "Display Name",
        accessor: "displayName",
      },
      {
        Header: "Output Name",
        accessor: "outputName",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ row }) => {
          return (
            <span>{moment(row.original.startDate).format("MM/DD/yyyy")}</span>
          );
        },
        sortType: sortDate,
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ row }) => {
          return (
            <span>{moment(row.original.endDate).format("MM/DD/yyyy")}</span>
          );
        },
        sortType: sortDate,
      },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => {
          return (
            <EditButton onClick={() => openSemesterFormModal(row.original)}>
              Edit
            </EditButton>
          );
        },
      },
    ],
    []
  );

  useAsyncEffect(async (loadSemesters) => {
    setLoading(true);
    try {
      const semesters = await getSemesters();
      setSemesters(
        JSON.parse(
          JSON.stringify(
            semesters.map((semester) => {
              return { ...semester.attributes, id: semester.objectId };
            })
          )
        )
      );
      setLoading(false);
    } catch (e) {
      alert("Unable to load semesters. Please try again later.");
      setLoading(false);
    }
  }, []);

  return (
    <>
      <SpinnerOverlay loading={loading} />

      <Modal
        open={formModalVisibility}
        toggleModal={handleSemesterFormModalToggle}
        header="Semester Form"
      >
        <SemesterForm
          semester={selectedForUpdate}
          onSubmit={handleSemesterSubmit}
        />
      </Modal>

      {selectedForDeletion && (
        <Modal
          open={deletionModalVisibility}
          toggleModal={handleDeleteModalToggle}
          header="Delete Semester(s)"
        >
          <DeleteConfirmation
            onDelete={handleRowDeletion}
            items={selectedForDeletion.map(
              (semester) => semester.original.displayName
            )}
          />
        </Modal>
      )}

      {semesters && (
        <SearchableTable
          columns={columns}
          data={semesters}
          onRowDeletion={openSemesterDeletionModal}
          onRowAddition={() => setModalFormVisibility(true)}
        />
      )}
    </>
  );
}

const EditButton = styled(Button)`
  background-color: #3182bd;
`;
