import React, { useCallback } from "react";
import TopicList from "./TopicList";
import Button from "../../shared/Button";
import moment from "moment";
import styled from "styled-components";

export default function WeekItem({
  date,
  onAddTopicClick,
  weekIndex,
  weekNumber,
  ...rest
}) {
  const generateDateLabel = useCallback(() => {
    const startDate = moment(date);
    const nextWeek = moment(date).endOf("isoWeek");
    const endDate = nextWeek.isSame(startDate, "month")
      ? nextWeek.format("D")
      : nextWeek.format("MMM. D");

    return `${moment(startDate).format("MMM. D")}-${endDate}`;
  }, [date]);

  const dateLabel = generateDateLabel();
  return (
    <TopicListContainer>
      <WeekLabel>
        Week {weekIndex + 1} - {dateLabel}
      </WeekLabel>
      <TopicList weekNumber={weekNumber} {...rest} />
      <AddButton onClick={() => onAddTopicClick(weekNumber)}>
        Add Topic
      </AddButton>
    </TopicListContainer>
  );
}

const TopicListContainer = styled("div")`
  margin-top: 15px;
  border: 1px solid white;
  border-radius: 12px;
  padding: 10px 10px 15px 10px;
`;

const WeekLabel = styled("div")`
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #e8e8e8;
  font-weight: 700;
`;

const AddButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  padding: 12px 10px;
  font-size: 1rem;
  border-radius: 20px;
  margin-top: 2rem;
  font-weight: 700;
`;
