import styled from "styled-components";

export default styled("button")`
  border: none;
  background: #16c640;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  :hover {
    opacity: ${(props) => (props.disabled ? 0.7 : 1)};
  }
`;
