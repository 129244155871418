import React from "react";
import styled from "styled-components";

const BorderlessTable = ({ children }) =>
(
  <TableRoot>
    <tbody>
      {children}
    </tbody>
  </TableRoot>
);


export default BorderlessTable;

const TableRoot = styled('table')`
  position: relative;
  border-collapse: collapse;
  border-spacing: 1; 
  background: white;
  width: 100%;
  margin: 0 auto;
  
  thead tr {
    background-color: #36304a;
    color: white; 
    height: 60px; 
  }

  tr { 
    font-family: -apple-system, BlinkMacSystemFont, 
      'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 
      'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif; 
    color: #808080;
    line-height: 1.2;
    font-weight: unset;
    height: 50px;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  
  th,
  td {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    border-collapse: collapse;
    margin: 10px;
    height: 30px;
    padding: 0 7px;

    :last-child {
      border-right: 0;
    }
  }

  tr:nth-child(even) {
  background-color: #f5f5f5;
}
`; 