/**
 * function getUrlParams
 * @param parameters - Array of url paramater keys to find
 * @param required - Boolean flag (true by default) that allows promise
 *                   to reject if one of the given parameter keys is not present
 * @returns Resolved promise containing params object
 */
export default function getUrlParams(parameters, required = true) {
  return new Promise((resolve, reject) => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    for (let param of parameters) {
      if (required && (!urlParams.has(param) || !urlParams.get(param))) {
        reject(`Missing or empty URL parameter of ${param}`);
      }
    }
    const entries = urlParams.entries();
    resolve(Object.fromEntries(entries));
  });
}
