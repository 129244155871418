import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export default function SubjectToggle({ onChange, defaultValue, visibility }) {
  const [radioValue, setRadioValue] = useState(defaultValue ?? "micro");

  useEffect(() => {
    setRadioValue(defaultValue);
  }, [defaultValue]);

  const handleSubjectChange = useCallback(
    (e) => {
      const value = e.target.value;
      setRadioValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <RadioInputRow visibility={visibility ? 1 : 0}>
      <RadioItem>
        <label>Micro</label>
        <input
          type="radio"
          value="micro"
          onChange={handleSubjectChange}
          checked={radioValue === "micro"}
          name="micro"
        />
      </RadioItem>

      <RadioItem>
        <label>Macro</label>
        <input
          type="radio"
          value="macro"
          onChange={handleSubjectChange}
          checked={radioValue === "macro"}
          name="macro"
        />
      </RadioItem>
    </RadioInputRow>
  );
}

const RadioInputRow = styled("div")`
  display: inline-flex;
  margin: 25px 0 15px 60px;
  padding-left: auto;
  font-size: 13px;
  label {
    line-height: 0px;
  }
  visibility: ${(props) => (props.visibility ? "visible" : "hidden")};
`;

const RadioItem = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
`;
