import React, { useCallback, useState } from "react";

import Button from "../../shared/Button";
import styled from "styled-components";

export default function CustomTopicForm({ onAddCustomTopic }) {
  const [topicName, setTopicName] = useState("");

  const handleTopicNameChange = useCallback((e) => {
    const value = e.target.value;
    setTopicName(value);
  }, []);

  const handleAddCustomTopic = useCallback(() => {
    if (topicName) {
      onAddCustomTopic(topicName);
      setTopicName("");
    }
  }, [onAddCustomTopic, topicName]);

  return (
    <Root>
      <Input
        value={topicName}
        placeholder="Enter custom topic"
        onChange={handleTopicNameChange}
      />
      <AddButton onClick={handleAddCustomTopic}>+</AddButton>
    </Root>
  );
}

const Root = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Input = styled("input")`
  border: none;
  background-color: #ededed;
  border-radius: 8px;
  padding: 10px 1rem;
  width: 80%;
  font-size: 1.1rem;
`;

const AddButton = styled(Button)`
  padding: 10px 10px;
  width: 15%;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 0;
`;
