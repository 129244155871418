import React, { useCallback, useState } from "react";
import Parse from "parse";
import useInputState from "@emberex/react-utils/lib/useInputState";
import styled from "styled-components";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import { useHistory, useLocation, withRouter } from "react-router-dom";

const Login = () => {
  const [username, handleUsernameChange] = useInputState("");
  const [password, handlePasswordChange] = useInputState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  let history = useHistory();
  let { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = useCallback(async () => {
    setLoading(true);
    try {
      if (!username) {
        throw Error("Username is required.");
      }
      if (!password) {
        throw Error("Password is required.");
      }

      await Parse.User.logIn(username, password);
      setLoading(false);
      from.pathname !== "/" ? history.replace(from) : history.push("/users");
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  }, [from, history, password, username]);

  return (
    <Root>
      <SpinnerOverlay loading={loading} />
      <TextInput
        type="text"
        onChange={handleUsernameChange}
        value={username}
        name="username"
        placeholder="Username"
      />
      <TextInput
        type="password"
        onChange={handlePasswordChange}
        value={password}
        name="email"
        placeholder="Password"
      />
      <GreenButton onClick={handleLogin}>Login</GreenButton>
      <ErrorText>{errorMessage}</ErrorText>
    </Root>
  );
};

export default withRouter(Login);

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  width: 357px;
  justify-content: center;
`;

const TextInput = styled("input")`
  padding: 17px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const ErrorText = styled("div")`
  height: 15px;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  color: white;
`;

const GreenButton = styled("button")`
  text-transform: uppercase;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  color: white;
  margin-top: 20px;
  background: #16c640;
  padding: 10px 17px;
  cursor: pointer;
`;
