import React, { useState } from "react";
import styled from "styled-components";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import StartDateSelectionOverlay from "./StartDateSelectionOverlay";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import WarningMessage from "../shared/WarningMessage";
import SemesterTabManager from "./SemesterTabManager";
import MobileScheduler from "./mobileScheduler/MobileScheduler";
import CopyScheduler from "./shared/CopyScheduler";
import getUrlParams from "../../utils/getUrlParams";
import { getSemester } from "../../helpers/back4app";
import { isMobile } from "../../utils/screen";
import useUser from "../../hooks/useUser";
import useSemester from "../../hooks/useSemester";
import SUBJECTS from "../../constants/subjects";
import { findCourseTemplateMapping } from "../../api/course-templates";
import { useHistory } from "react-router-dom";
import TemplateScheduler from "./TemplateScheduler";
import getUserFromSemesterId from "../../api/user/getUserFromSemesterId";
import { ACCOUNT_DASHBOARD_ROUTE } from "../../constants/routes/accountRoutes";
import generateUrlWithParams from "../../utils/generateUrlWithParams";

export default function Scheduler() {
  const [loading, setLoading] = useState(false);
  const [classStartDate, setClassStartDate] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [urlParams, setUrlParams] = useState();
  const [useTemplateScheduler, setUseTemplateScheduler] = useState();

  const { loadUserSemesters, semesterSchedules, setUserEmail } = useUser();
  const { loadApplicationSemesters, semesters } = useSemester();

  const history = useHistory();

  const handleStartDateSubmission = async (startDate) => {
    setClassStartDate(startDate);
  };

  useAsyncEffect(async (isCancelled) => {
    setLoading(true);
    try {
      const initParams = await getUrlParams(["transition", "email"]);
      setUserEmail(initParams?.email);
      if (initParams.transition === "copy") {
        const paramsForCopyTransition = await getUrlParams([
          "email",
          "exid",
          "transition",
        ]);
        await loadUserSemesters(paramsForCopyTransition.email);
        setUrlParams(paramsForCopyTransition);
      } else {
        const paramsForNewTransition = await getUrlParams([
          "email",
          "exid",
          "transition",
          "teaching",
          "semester",
        ]);

        // Make sure record doesn't already exists
        const [existingUserRecord] = await getUserFromSemesterId({
          email: paramsForNewTransition.email,
          semesterId: paramsForNewTransition.semester,
        });

        if (existingUserRecord) {
          throw new Error("A schedule with this semester already exists!");
        }

        const [semester] = await getSemester(paramsForNewTransition.semester);

        const allowedSubject = SUBJECTS.find(
          (subject) =>
            subject.toLowerCase() ===
            paramsForNewTransition?.teaching.toLowerCase()
        );

        if (!allowedSubject) {
          throw new Error(
            `Invalid subject of ${paramsForNewTransition.teaching}`
          );
        }

        if (semester) {
          await loadUserSemesters(paramsForNewTransition.email);
          setUrlParams(paramsForNewTransition);
        } else {
          setErrorMessage(
            `Invalid semester ID of ${paramsForNewTransition.semester}`
          );
        }
      }
    } catch (e) {
      history.push(ACCOUNT_DASHBOARD_ROUTE);
    }
    if (!isCancelled()) {
      await loadApplicationSemesters();
    }
    setLoading(false);
  }, []);

  // Check if given subject is has a course template mapping
  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      const param = await getUrlParams(["teaching"]);

      const templateMapping = await findCourseTemplateMapping({
        courseName: param.teaching,
      });

      if (templateMapping.length) {
        setUseTemplateScheduler(true);
      }
    }
  }, []);

  /* Special case to handle econinbox.com sending 'reenroll' as a value 
     for the semester or teaching url parameters */
  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      const params = await getUrlParams(["semester", "teaching"]);
      if (
        params.semester.toLowerCase() === "reenroll" ||
        params.teaching.toLowerCase() === "reenroll"
      ) {
        history.push(
          generateUrlWithParams([
            {
              key: "email",
              value: params.email,
            },
            { key: "exid", value: params.exid },
            { key: "transition", value: "copy" },
          ])
        );
        history.go(0);
      }
    }
  }, []);

  const useCopyScheduler =
    !loading && urlParams && urlParams.transition === "copy" && semesters;
  const promptStartDateSelection =
    !loading && !classStartDate && urlParams && !useTemplateScheduler;

  return (
    <Root className={isMobile() ? "mobile-root" : null}>
      {loading && <SpinnerOverlay loading={loading} />}
      {errorMessage && !loading && <WarningMessage message={errorMessage} />}

      {promptStartDateSelection && !useCopyScheduler && (
        <StartDateSelectionOverlay
          onSubmit={handleStartDateSubmission}
          transition={urlParams.transition}
        />
      )}

      {useCopyScheduler && (
        <CopyScheduler
          userSemesterSchedules={semesterSchedules}
          urlParams={urlParams}
        />
      )}

      {useTemplateScheduler &&
        !loading &&
        semesters &&
        urlParams &&
        !useCopyScheduler && <TemplateScheduler urlParams={urlParams} />}

      {classStartDate && (
        <>
          {isMobile() ? (
            <MobileScheduler
              classStartDate={classStartDate}
              urlParams={urlParams}
            />
          ) : (
            <SemesterTabManager
              classStartDate={classStartDate}
              urlParams={urlParams}
            />
          )}
        </>
      )}
    </Root>
  );
}

const Root = styled("div")`
  color: white;
  font-size: 1.5rem;
  overflow-y: auto;
  justify-content: center;
`;
