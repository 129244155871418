import React, { useState } from "react";
import "./UploadSyllabus.css";
import { CSVReader } from "react-papaparse";
import {
  saveUser,
  updateUser,
  getUserBySemester,
} from "../../helpers/back4app";
import moment from "moment";
import { getMondays } from "../../utils/date";

export default function UploadSyllabus() {
  const [uploading, setUploading] = useState(false);
  const [endUpload, setEndUpload] = useState(false);

  let userData = [];
  let mondays = getMondays();

  function userSavedError() {
    alert("Something went wrong.");
  }

  let handleOnDrop = (data) => {
    generateData(data);
  };

  let handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  let handleOnRemoveFile = (data) => {
    console.log(data);
  };

  function generateData(data) {
    setUploading(true);
    setEndUpload(false);
    data.forEach((item, index) => {
      if (index === 0) return;
      let user = createUserData(item);
      console.log("item", item);
      let startMonday = getMonday(user.startDate);
      let weeks = getMondays(user.startDate);
      let startIndex = weeks
        .map(function (e) {
          return e.date;
        })
        .indexOf(startMonday);
      let sliced = weeks.slice(startIndex, weeks.length);
      user.weeks = createWeekData(user, item, sliced);
      restoreDuplicateTopics(user.weeks, user);
      let customTopicsInfo = createCustomTopicsInfo(user, item);
      user.customTopics = customTopicsInfo.onlyTopics;
      addCustomTopicInWeek(user.weeks, customTopicsInfo.weekNameTopics, sliced);
      const sorter = (a, b) => {
        return new Date(a.date) - new Date(b.date);
      };

      user.weeks = user.weeks.sort(sorter);
      userData.push(user);
    });

    let saved = 1;
    asyncForEach(userData, async (user) => {
      await delayExecution(1000);
      saveOrUpdate(user);

      if (saved === userData.length) {
        alert("Data Successfully uploaded");
      }

      saved++;
    });
    setUploading(false);
    setEndUpload(true);
  }

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  function delayExecution(ms) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  }

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1);
    let monday = new Date(d.setDate(diff));
    return moment(monday).format("YYYY/M/D");
  }

  function restoreDuplicateTopics(weeks, user) {
    weeks.forEach((week, index) => {
      let isDuplicate = false,
        numberOfDuplicateWeeks = 0;
      week.topic.forEach((topic, index) => {
        if (topic.includes("Duplicate Topic")) {
          isDuplicate = true;
          if (topic.includes("Duplicate Topic Email - 1")) {
            numberOfDuplicateWeeks = 1;
          } else {
            numberOfDuplicateWeeks = topic
              .split("Duplicate Topic - ")[1]
              .split(" Week Break")[0];
          }
          week.topic.splice(index, 1);
        }
      });

      if (isDuplicate) {
        let previousTopic =
          index === 0
            ? weeks[weeks.length - 1].topic[0]
            : weeks[index - 1].topic[0];
        if (previousTopic) {
          for (
            let i = index;
            i < index + parseInt(numberOfDuplicateWeeks);
            i++
          ) {
            if (i >= weeks.length) {
              weeks[i - weeks.length].topic.push(previousTopic);
            } else {
              weeks[i].topic.push(previousTopic);
            }
          }
        }
      }
    });
  }

  function createUserData(item) {
    let user = {
      semester: item.data[0],
      processed: item.data[1] === "1" ? true : false,
      updated: item.data[2] === "1" ? true : false,
      processedUpdate: item.data[3] === "1" ? true : false,
      userId: item.data[4],
      startDate: moment(item.data[5]).format("YYYY/M/DD"),
      email: item.data[6],
      subject: item.data[7],
      microAndMacro: false,
      //createdAt: '' //new Date() //moment(item.data[7]).format("DD MM YYYY HH:mm:ss"),
    };
    return user;
  }

  function createWeekData(user, item, sliced) {
    let weekIndex = Object.keys(user).length;
    // Object.keys(user).length;
    let weekData = [];
    let weeksArray = Array.from(Array(80), (_, i) => i + 1);
    weeksArray.forEach((week) => {
      let incWeek = week + 1;
      if (week > 30 && !(week > 53)) {
        if (week + 1 > 53) {
          incWeek = week + 1 - 53;
        } else {
          incWeek = week + 1;
        }
      }

      if (
        typeof item.data[weekIndex] != "undefined" ||
        item.data[weekIndex] != null
      ) {
        let thisWeek = getThisWeek(incWeek, sliced);
        let topics =
          item.data[weekIndex] !== ""
            ? sanitazeTopics(item.data[weekIndex])
            : "";
        topics =
          topics === ""
            ? []
            : getTopicsStringToArray(topics).filter(function (item) {
                return item;
              });

        if (thisWeek.date) {
          weekData.push({
            date: thisWeek.date,
            label: thisWeek.label,
            numberOfWeek: thisWeek.numberOfWeek,
            topic: topics,
            //"unsanitazeTopic": item.data[weekIndex]
          });
        }
      }
      weekIndex++;
    });
    return weekData;
  }

  function getThisWeek(week, sliced = []) {
    let result = {};
    sliced.forEach((d) => {
      if (d.numberOfWeek === week) {
        result = d;
        return;
      }
    });
    return result;
  }

  function sanitazeTopics(topics) {
    let sanitazeTopic = topics.replace(/; /g, ";");
    let adminTopics = [
      { text: "**LAST Scheduled EMAIL**", replacement: "" },
      { text: "Feedback Email (Final)", replacement: "" },
      { text: "Mid-Semester Feedback Email", replacement: "" },
      { text: "Multiple Email Notification", replacement: "" },
      { text: "Custom Topic", replacement: "" },
      { text: "Exam - General", replacement: "Exam" },
    ];
    adminTopics.forEach((adminTopic) => {
      sanitazeTopic = sanitazeTopic.replace(
        adminTopic.text + ";",
        adminTopic.replacement
      );
      sanitazeTopic = sanitazeTopic.replace(
        adminTopic.text,
        adminTopic.replacement
      );
    });
    return sanitazeTopic;
  }

  function getTopicsStringToArray(topics) {
    return topics.split(";");
  }

  function createCustomTopicsInfo(user, item) {
    let weekNameTopics = [];
    let onlyTopics = ["Skip/No Class", "Exam", "LAST CLASS"];
    let index = item.data.length - 1;
    let customTopics = item.data[index];
    if (customTopics) {
      customTopics = customTopics.replace(/; /g, ";");
      let topicArr = customTopics.split(";");
      topicArr.forEach((topic) => {
        if (topic === "") return;
        let topicName = topic.split(": ");
        onlyTopics.push(topicName[1]);
        weekNameTopics.push({
          numberOfWeek: topicName[0].split(" ")[1], //- 1,
          name: topicName[1],
        });
      });
    }
    return {
      onlyTopics: onlyTopics,
      weekNameTopics: weekNameTopics,
    };
  }

  function addCustomTopicInWeek(weeks, weekNameTopics, sliced) {
    weekNameTopics.forEach((t) => {
      let topickWeekNumber =
        parseInt(t.numberOfWeek) >= 53
          ? parseInt(t.numberOfWeek) + 1 - 53
          : parseInt(t.numberOfWeek) + 1;
      let match = false;
      weeks.forEach((w) => {
        if (topickWeekNumber === w.numberOfWeek) {
          w.topic.push(t.name);
          match = true;
        }
      });
      if (!match) {
        let thisWeek = getThisWeek(topickWeekNumber, sliced);
        weeks.push({
          date: thisWeek.date,
          label: thisWeek.label,
          numberOfWeek: thisWeek.numberOfWeek,
          topic: [t.name],
        });
      }
    });
  }

  function saveOrUpdate(user) {
    if (!user.email) return;
    getUserBySemester(user).then(async (response) => {
      if (response.length) {
        user.email = response[0].attributes.email;
        await updateUser(user.userId, user, () => {});
      } else {
        saveUser(user, () => {}, userSavedError);
      }
    });
  }

  return (
    <CSVReader
      onDrop={handleOnDrop}
      onError={handleOnError}
      noDrag
      addRemoveButton
      config={{
        delimiter: ",",
      }}
      onRemoveFile={handleOnRemoveFile}
    >
      <span>Click to upload.</span>
    </CSVReader>
  );
}
