import axios from "axios";
import Parse from "parse";
import { APP_ID, JS_KEY, API_KEY } from "../constants/back4app";

Parse.serverURL = "https://parseapi.back4app.com"; // This is your Server URL
Parse.initialize(
  APP_ID, // This is your Application ID
  JS_KEY // This is your Javascript key
);

const headers = {
  "X-Parse-Application-Id": APP_ID,
  "X-Parse-REST-API-Key": API_KEY,
  "Content-Type": "application/json",
};

export async function getUsers() {
  const UserData = Parse.Object.extend("UserData");
  const query = new Parse.Query(UserData);
  query.notEqualTo("email", null);
  const rowCount = await query.count();

  return query.limit(rowCount).ascending("createdAt").find();
}

export async function getSemesters() {
  const Semesters = Parse.Object.extend("Semester");
  const query = new Parse.Query(Semesters);
  const data = await query.ascending("startDate").find();

  return data;
}

export async function getSemester(semesterId) {
  const Semester = Parse.Object.extend("Semester");
  const queryEqual = new Parse.Query(Semester);
  queryEqual.equalTo("semesterId", semesterId);

  const query = Parse.Query.or(queryEqual);
  return await query.find();
}

export async function deleteSemester(semesterId) {
  const semesterData = Parse.Object.extend("Semester");
  const query = new Parse.Query(semesterData);

  try {
    const semester = await query.equalTo("semesterId", semesterId).first();
    await semester.destroy();
  } catch (e) {
    alert("Cannot delete user record at this moment.");
  }
}

export async function addSemester(data) {
  const semester = await getSemester(data.semesterId);

  if (Array.isArray(semester) && semester.length) {
    alert("Semester ID already exists!");
  } else {
    const Semester = Parse.Object.extend("Semester");
    const semester = new Semester();
    for (const key in data) {
      semester.set(key, data[key]);
    }
    await semester.save();
  }
}

export async function updateSemester(semesterId, properties) {
  let query = new Parse.Query("Semester");
  try {
    const semesterData = await query.equalTo("semesterId", semesterId).first();

    for (const key in properties) {
      semesterData.set(key, properties[key]);
    }
    await semesterData.save();
  } catch (e) {
    alert(`Failed to update semester: ${e.message}`);
  }
}

export async function updateUser(userObjectId, properties) {
  let query = new Parse.Query("UserData");
  try {
    const userData = await query.equalTo("objectId", userObjectId).first();

    for (const key in properties) {
      userData.set(key, properties[key]);
    }
    await userData.save();
  } catch (e) {
    alert(`Failed to update user: ${e.message}`);
  }
}

export async function saveUserAsync(data) {
  getUser(data.userId).then(async (response) => {
    if (response.length) {
      alert("User ID already exists!");
      return;
    }

    await axios.post(
      "https://parseapi.back4app.com/classes/UserData",
      JSON.stringify({ ...data, email: textToLowerCase(data.email) }),
      { headers: headers }
    );
  });
}

export function saveUser(user, onSuccess, onError) {
  getUser(user.userId).then((response) => {
    if (response.length) {
      alert("User ID already exists!");
      return;
    }

    axios
      .post(
        "https://parseapi.back4app.com/classes/UserData",
        JSON.stringify({ ...user, email: textToLowerCase(user.email) }),
        { headers: headers }
      )
      .then((response) => {
        if (response.data.objectId) {
          onSuccess();
        } else {
          onError();
        }
      });
  });
}

export function getUserBySemester(user) {
  const UserData = Parse.Object.extend("UserData");
  const queryEqual = new Parse.Query(UserData);
  const queryOrEqual = new Parse.Query(UserData);
  let email = textToLowerCase(user.email);
  queryEqual.equalTo("email", email);

  const query = Parse.Query.or(queryEqual, queryOrEqual);
  query.equalTo("semester", user.semester);
  query.limit(2);

  return query.find();
}

export function getUser(email) {
  const UserData = Parse.Object.extend("UserData");
  const queryEqual = new Parse.Query(UserData);
  queryEqual.equalTo("email", textToLowerCase(email));

  const query = Parse.Query.or(queryEqual);
  query.limit(2);

  return query.find();
}

export async function deleteUser(objectId) {
  const UserData = Parse.Object.extend("UserData");
  const query = new Parse.Query(UserData);

  try {
    const user = await query.equalTo("objectId", objectId).first();
    await user.destroy();
  } catch (e) {
    alert("Cannot delete user record at this moment.");
  }
}

export async function getUserSemesters(email) {
  const UserData = Parse.Object.extend("UserData");
  const findByEmail = new Parse.Query(UserData);
  findByEmail.equalTo("email", textToLowerCase(email));

  return await Parse.Query.or(findByEmail).find();
}

function textToLowerCase(text) {
  return text ? text.toLowerCase() : text;
}
