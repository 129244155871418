import moment from "moment";

export default function formatUserScheduleDateRange(userSemester) {
  if (Array.isArray(userSemester?.weeks)) {
    const latestWeekIndex = userSemester.weeks.length - 1;
    const latestWeekDate = userSemester.weeks[latestWeekIndex].date;
    const startDate = moment.utc(userSemester.startDate).format('MMM D');
    const endDate = moment(latestWeekDate).format('MMM D, YYYY');
    
    return `${startDate}-${endDate}`;
  }
  return '';
}