import parse from "../parse";
import {
  CoursesClass,
  CourseLengthsClass,
  CoursePacingsClass,
  CourseTemplateMappingsClass,
} from "../../constants/classes";
import { getCourseByName } from "../courses";

export default async function findCourseTemplateMapping({
  courseId,
  courseName,
  courseLengthId,
  coursePacingId,
}) {
  const CoursePacings = parse.Object.extend(CoursePacingsClass);
  const CourseLengths = parse.Object.extend(CourseLengthsClass);
  const Course = parse.Object.extend(CoursesClass);
  const courseTemplateMappings = parse.Object.extend(
    CourseTemplateMappingsClass
  );

  const query = new parse.Query(courseTemplateMappings);

  if (courseName) {
    const [course] = await getCourseByName(courseName);
    if (!course) {
      return [];
    }

    query.equalTo("course", new Course({ id: course.id }));
  }

  if (courseId) {
    query.equalTo("course", new Course({ id: courseId }));
  }

  if (courseLengthId) {
    query.equalTo("courseLength", new CourseLengths({ id: courseLengthId }));
  }

  if (coursePacingId) {
    query.equalTo("coursePacing", new CoursePacings({ id: coursePacingId }));
  }

  return await query.find();
}
