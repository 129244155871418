import React from "react";
import SemesterSubmissionConfirmation from "./SemesterSubmissionConfirmation";

export default function NewSemesterSubmissionConfirmation(props) {
  return (
    <SemesterSubmissionConfirmation {...props}>
      <p>
        You're all set. We'll be sending you a weekly email chock full of
        resources that match the topic you're teaching the following week. You
        can always come back and update your schedule anytime.
      </p>
    </SemesterSubmissionConfirmation>
  );
}
