import moment from "moment";
import booleanToNumber from "./booleanToNumber";
import customTopicMappings from "./mappings/customTopicMappings";
import duplicateTopicMappings from "./mappings/duplicateTopicMappings";
import multipleEmailNotifications from "./mappings/multipleEmailNotifications";
import examMappings from "./mappings/examMappings";
import feedbackEmailMappings from "./mappings/feedbackEmailMappings";
import alternateTopicNameMappings from "./mappings/alternateTopicNameMappings";
import { semicolonJoin } from "./joinAndSplit";
import leapYear from "./leapYear";
import shiftLeft from "./shiftLeft";
import { getSemesters } from "../../../../helpers/back4app";
import getCourseHolidays from "../../../../api/courses/getCourseHolidays";
import holidayMappings from "./mappings/holidayMappings";

export function downloadCSV(csvString, filename) {
  const universalBOM = "\uFEFF";
  const link = document.createElement("a");
  link.setAttribute(
    "href",
    "data:text/csv; charset=utf-8," +
      encodeURIComponent(universalBOM + csvString.trim())
  );
  link.setAttribute(
    "download",
    filename || `econinbox_users_${moment().format()}.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function generateCSVArray(users) {
  // Get available semesters
  const parseSemesters = await getSemesters();
  const holidays = await getCourseHolidays();

  const appSemesterList = parseSemesters.map((semester) => semester.attributes);
  const holidaysList = [
    ...new Set(holidays.map((holiday) => holiday.attributes.name)),
  ];

  return users.map((user) => {
    return generateRowArray({
      row: user,
      appSemesters: appSemesterList,
      holidays: holidaysList,
    });
  });
}

export function generateCSVString(header, rows) {
  const headerStr = header.join() + "\n";
  const rowsArray = rows.map((row) => {
    return row.join() + "\n";
  });

  return [headerStr, ...rowsArray].join("");
}

function generateRowArray({ row, appSemesters, holidays }) {
  let semester = row.semester;
  if (row.semesterId) {
    const appSemester = appSemesters.find(
      (semester) => semester.semesterId === row.semesterId
    );
    if (appSemester) {
      semester = appSemester.outputName;
    }
  }

  let userRow = [
    semester,
    booleanToNumber(row.processed),
    booleanToNumber(row.updated),
    booleanToNumber(row.processedUpdate),
    row.userId,
    moment(row.startDate).format("YYYY-MM-DD"),
    row.email,
    row.subject,
    moment(row.createdAt).format("MM/DD/YYYY LT"),
  ];

  let topicWeeks = [];
  const today = new Date();
  const totalWeeks = leapYear(today.getFullYear()) ? 52 : 51;

  for (let i = 0; i <= totalWeeks; i++) {
    topicWeeks.push("");
  }

  row.weeks.forEach((week) => {
    topicWeeks[week.numberOfWeek - 1] = week.topic.filter((w) => w);
  });

  topicWeeks = shiftLeft(
    topicWeeks.map((weeks) => (weeks.length ? weeks : ""))
  );

  const weeksWithCustomTopics = customTopicMappings(
    topicWeeks,
    row.customTopics
  );

  const weeksWithHolidaysMappings = holidayMappings(
    weeksWithCustomTopics.weeks,
    holidays
  );

  const weeksWithDuplicateTopics = duplicateTopicMappings(
    weeksWithHolidaysMappings
  );

  const weeksWithMultipleEmailNotifications = multipleEmailNotifications(
    weeksWithDuplicateTopics
  );

  const weeksWithExamMappings = examMappings(
    weeksWithMultipleEmailNotifications
  );

  const weeksWithFeedbackEmailMappings = feedbackEmailMappings(
    weeksWithExamMappings,
    row.weeks
  );

  const weeksWithAlternateNamesMappings = alternateTopicNameMappings(
    weeksWithFeedbackEmailMappings
  );

  const topics = weeksWithAlternateNamesMappings.map((week) => {
    if (Array.isArray(week)) {
      const cleanArr = week.filter((weekItem) => weekItem);
      return cleanArr.length ? semicolonJoin(cleanArr) : "";
    } else {
      return week;
    }
  });

  return [...userRow, ...topics, weeksWithCustomTopics.customTopics];
}
