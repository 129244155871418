import React from "react";
import Row from "../shared/Row";
import styled from "styled-components";

export default function RadioButton({ label, checked, onClick }) {
  return (
    <Root>
      <Container>
        <Input onClick={onClick} type="radio" name="radio" />
        <Checkmark checked={checked}></Checkmark>
      </Container>
      <RadioLabel>{label}</RadioLabel>
    </Root>
  );
}

const Root = styled(Row)`
  align-items: center;
`;

const Input = styled("input")`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const Checkmark = styled("span")`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  background-color: ${(props) => (props.checked ? "white" : "")};

  :after {
    content: "";
    position: absolute;
    display: ${(props) => (props.checked ? "block" : "none")};
  }
`;

const Container = styled("label")`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 22px;

  user-select: none;
  ${Checkmark}:after {
    top: 8px;
    left: 8px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #2196f3;
  }
`;

const RadioLabel = styled("div")`
  font-size: 1.25rem;
  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;
