import { useEffect, useState } from "react";
import moment from "moment";
import { SEMESTER, EMAIL } from "../constants/scheduleBuilderUrlParams";
import generateUrlWithParams from "../utils/generateUrlWithParams";
import { SECOUND_SEMESTER_COURSE_ROUTE } from "../constants/routes/schedulerRoutes";
import { ACCOUNT_SCHEDULE_UPDATE_ROUTE } from "../constants/routes/accountRoutes";

const useAccountDashboard = ({ userSemesters, appSemestersContext }) => {
  const [secondSemesterCourse, setSecondSemesterCourseUrl] = useState();
  const [previousSemesterUpdate, setPreviousSemesterUpdate] = useState();
  const [newSemester, setNewSemester] = useState()
  const [currentSemesterUpdate, setCurrentSemesterUpdate] = useState();
  const [futureSemesterUpdates, setFutureSemesterUpdates] = useState([]);

  const { getSemesterBySemesterId } = appSemestersContext;

  useEffect(() => {
    const today = new Date();
    const currentAppSemester = appSemestersContext.semesters.find(
      appSemester =>
        moment(today).isBetween(
          appSemester.startDate,
          appSemester.endDate
        )
    );

    const previousAppSemester = appSemestersContext.semesters.find(
      appSemester => moment(
        moment.utc(appSemester.endDate)
          .format("MM/DD/YYYY"))
        .isSame(moment.utc(currentAppSemester.startDate)
          .subtract(1, 'days')
          .format("MM/DD/YYYY")
        )
    );

    const userFutureSemesters = userSemesters.filter(semester =>
      moment.utc(getSemesterBySemesterId(semester.semesterId).startDate)
        .isAfter(
          currentAppSemester.endDate
        )
    );

    const appFutureSemesters = appSemestersContext.semesters.filter(
      appSemester =>
        moment.utc(appSemester.startDate)
          .isAfter(
            moment.utc(currentAppSemester.endDate)
          )
    );

    const userWithCurrentAppSemester = userSemesters.find(
      semester =>
        semester.semesterId === currentAppSemester.semesterId
    );

    if (userWithCurrentAppSemester) {
      setCurrentSemesterUpdate(
        getSemesterUpdateUrl(userWithCurrentAppSemester)
      );
      setSecondSemesterCourseUrl(
        {
          semester: userWithCurrentAppSemester,
          url: SECOUND_SEMESTER_COURSE_ROUTE,
        }
      );
    }

    if (userFutureSemesters) {
      setFutureSemesterUpdates(
        getFutureSemesterUpdateUrls(userFutureSemesters)
      );
    }

    if (appFutureSemesters && userSemesters.length) {
      findAndSetNewSemesterUrl({ userFutureSemesters, appFutureSemesters, userEmail: userSemesters[0].email });
    }

    if (previousAppSemester) {
      findPreviousSemesterUpdate({ previousAppSemester });
    }
  }, []);

  const getSemesterUpdateUrl = (userWithCurrentAppSemester) => {
    const updateUrlParams = generateUrlWithParams([
      { key: SEMESTER, value: userWithCurrentAppSemester.semesterId },
      { key: EMAIL, value: userWithCurrentAppSemester.email }
    ]);

    return {
      semester: userWithCurrentAppSemester,
      url: `${ACCOUNT_SCHEDULE_UPDATE_ROUTE}/${updateUrlParams}`
    };
  }

  const getFutureSemesterUpdateUrls = (userFutureSemesters) => {
    return userFutureSemesters.map(semester => {
      const urlParams = generateUrlWithParams([
        { key: SEMESTER, value: semester.semesterId },
        { key: EMAIL, value: semester.email }
      ]);
      return {
        semester: semester,
        url: `${ACCOUNT_SCHEDULE_UPDATE_ROUTE}/${urlParams}`
      }
    });
  }

  const findPreviousSemesterUpdate = ({ 
    previousAppSemester, 
  }) => {
    const userPreviousSemesterSchedule = userSemesters.find(userSemester =>
      userSemester.semesterId === previousAppSemester.semesterId
    );
    if (userPreviousSemesterSchedule) {
      const userPreviousSemesterScheduleWeekCount = 
        userPreviousSemesterSchedule.weeks.length - 1;
 
      for (let i = userPreviousSemesterScheduleWeekCount; i !== 0; i--){
        if(userPreviousSemesterSchedule.weeks[i].topic.length > 0){
          const today = new Date(); 
          const latestWeekWithTopics =
          userPreviousSemesterSchedule.weeks[i];
  
          if (moment(moment(latestWeekWithTopics.date)
            .format('MM/DD/YYYY'))
            .isSameOrAfter(
              moment.utc(today)
                .subtract(14, 'days')
                .format('MM/DD/YYYY'))
          ) {
            const urlParams = generateUrlWithParams([
              { key: SEMESTER, value: userPreviousSemesterSchedule.semesterId },
              { key: EMAIL, value: userPreviousSemesterSchedule.email }
            ]);
    
            setPreviousSemesterUpdate({
              url: `${ACCOUNT_SCHEDULE_UPDATE_ROUTE}/${urlParams}`,
              semester: userPreviousSemesterSchedule,
            });
          }
          break; 
        }
      }
    }
  }

  const findAndSetNewSemesterUrl = ({ userFutureSemesters, appFutureSemesters, userEmail }) => {
    for (const appFutureSemester of appFutureSemesters) {
      const userHasFutureSemester = userFutureSemesters.some(
        userFutureSemester =>
          userFutureSemester.semesterId === appFutureSemester.semesterId
      );
      if (!userHasFutureSemester) {
        setNewSemester({
          semester: appFutureSemester,
          url: encodeURI(`https://www.econinbox.com/reenroll/${generateUrlWithParams([{ key: 'fld34', value: userEmail }])}`)
        });
      }
    }
  }

  return {
    secondSemesterCourse,
    newSemester,
    currentSemesterUpdate,
    futureSemesterUpdates,
    previousSemesterUpdate
  };
}

export default useAccountDashboard; 