import moment from "moment";

export function getMondays(startDate = "2021-01-01"){
    let year = new Date(startDate).getFullYear();
    let date = year == '2020' ? new Date("2020-07-01") : new Date("2021-01-01")

    let d = date;

    d.setDate(1);
    
    return generateMondaysByYear(date, year)

    function generateMondaysByYear(dateObj, forYear) {
        let mondays = [];
        // Get the first Monday in the month
        while (dateObj.getDay() !== 1) {
            dateObj.setDate(dateObj.getDate() + 1);
        }

        // Get all the other Mondays in the month
        // == 2020) || (dateObj.getFullYear() == 2021 && dateObj.getMonth() < 6)
        let weekCount = 0;
        // while (dateObj.getFullYear() == forYear) {
        while (weekCount < 53) {
            var pushDate = new Date(dateObj.getTime());
            let date = pushDate.getFullYear() + '/' + (pushDate.getMonth()+1) + '/' + pushDate.getDate();
            mondays.push({date: date, label: moment(date).format("ddd. MMM D"), numberOfWeek: moment(date).isoWeek(), topic: []});
            dateObj.setDate(dateObj.getDate() + 7);
            weekCount++;
        }
        return mondays;
    } 
} 




// let d = new Date("2020-08-01"),
// year = d.getFullYear(),
// mondays = [];

// d.setDate(1);

// // Get the first Monday in the month
// while (d.getDay() !== 1) {
// d.setDate(d.getDate() + 1);
// }

// // Get all the other Mondays in the month
// while ((d.getFullYear() == 2020) || (d.getFullYear() == 2021 && d.getMonth() < 6)) {
// var pushDate = new Date(d.getTime());
// let date = pushDate.getFullYear() + '/' + (pushDate.getMonth()+1) + '/' + pushDate.getDate();
// mondays.push({date: date, label: moment(date).format("ddd. MMM D"), numberOfWeek: moment(date).isoWeek(), topic: []});
// d.setDate(d.getDate() + 7);
// }
