import Parse from "parse";
import { APP_ID, JS_KEY } from "../constants/back4app";

Parse.serverURL = "https://parseapi.back4app.com"; // This is your Server URL
Parse.initialize(
  APP_ID, // This is your Application ID
  JS_KEY // This is your Javascript key
);

export default Parse; 
