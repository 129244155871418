import React from "react";
import RemovableTopicItem from "../shared/RemovableTopicItem";
import styled from "styled-components";

export default function TopicList({ topics, weekNumber, onDeleteTopic }) {
  return (
    <div>
      {topics.length ? (
        <>
          {topics.map((topic, i) => {
            return (
              <RemovableTopicItem
                key={`${weekNumber}-${topic}-${i}`}
                customStyles={{
                  width: "100%",
                  fontSize: "1rem",
                  padding: "14px 10px",
                  border: "none",
                  borderRadius: "8px",
                  marginTop: "7px",
                }}
                topicName={topic}
                onRemove={() => onDeleteTopic(topic, weekNumber)}
              />
            );
          })}
        </>
      ) : (
        <EmptyWeekLabel>Empty</EmptyWeekLabel>
      )}
    </div>
  );
}

const EmptyWeekLabel = styled("div")`
  padding: 10px 0;
  text-align: center;
  color: white;
  margin-bottom: 5px;
  font-size: 1rem;
  border: 1px dashed white;
  border-radius: 8px;
`;
