import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../shared/Modal";
import Dropdown from "../../shared/Dropdown";
import Button from "../../shared/Button";
import {
  MICRO_TOPICS,
  MACRO_TOPICS,
  INTRO_TOPICS,
} from "../../../constants/topics";
import styled from "styled-components";
import CustomTopicForm from "./CustomTopicForm";
import useUser from "../../../hooks/useUser";
import getSelectedSubject from "../helper/getSelectedSubject";

const DEFAULT_TOPIC = "MICRO";
const DEFUALT_TOPIC_LIST = ["MICRO", "MACRO", "CUSTOM"];

export default function AddTopicModal({
  userSubject,
  weekNumber,
  onAddTopic,
  toggleModal,
  customTopics,
  ...rest
}) {
  const [subjectList, setSubjectList] = useState(DEFUALT_TOPIC_LIST);
  const [selectedTopic, setSelectedTopic] = useState();
  const [selectedSubject, setSelectedSubject] = useState(DEFAULT_TOPIC);

  const { addCustomTopicToActiveSemester } = useUser();

  const handleSubjectTabClick = useCallback((subject) => {
    setSelectedTopic(null);
    setSelectedSubject(subject);
  }, []);

  const handleTopicChange = useCallback(({ value }) => {
    setSelectedTopic(value);
  }, []);

  const handleSaveTopicClick = useCallback(() => {
    onAddTopic(selectedTopic, weekNumber);
    toggleModal(false);
  }, [onAddTopic, selectedTopic, toggleModal, weekNumber]);

  const handleAddCustomTopic = useCallback(
    (topicName) => {
      addCustomTopicToActiveSemester(topicName);
    },
    [addCustomTopicToActiveSemester]
  );

  useEffect(() => {
    const subject = getSelectedSubject(userSubject);

    if (subject === "intro") {
      setSubjectList(["INTRO", "CUSTOM"]);
    } else {
      setSubjectList(DEFUALT_TOPIC_LIST);
    }

    setSelectedSubject(subject.toUpperCase());
  }, [userSubject]);

  const topics =
    selectedSubject === "MICRO"
      ? MICRO_TOPICS
      : selectedSubject === "INTRO"
      ? INTRO_TOPICS
      : selectedSubject === "MACRO"
      ? MACRO_TOPICS
      : customTopics;

  return (
    <Root>
      <Modal header="Add Topic" toggleModal={toggleModal} {...rest}>
        <ModalContent>
          <TopicTabs>
            {subjectList.map((subject) => {
              return (
                <TabItem
                  key={subject}
                  active={selectedSubject === subject}
                  onClick={() => handleSubjectTabClick(subject)}
                >
                  {subject}
                </TabItem>
              );
            })}
          </TopicTabs>
          {selectedSubject === "CUSTOM" && (
            <CustomTopicForm onAddCustomTopic={handleAddCustomTopic} />
          )}
          <Dropdown
            initValue={selectedTopic}
            options={topics.map((topic) => {
              return { value: topic, key: topic };
            })}
            onChange={handleTopicChange}
          />
          <ButtonContainer>
            <SaveButton
              onClick={handleSaveTopicClick}
              disabled={!selectedTopic}
            >
              Save
            </SaveButton>
            <CancelButton onClick={() => toggleModal(false)}>
              Cancel
            </CancelButton>
          </ButtonContainer>
        </ModalContent>
      </Modal>
    </Root>
  );
}

const Root = styled("div")`
  .modal-content {
    width: 95% !important;
  }
  .modal-header {
    font-size: 1.5rem;
  }
  @media (min-width: 600px) {
    .modal-content {
      max-width: 600px;
    }
  }
`;

const TopicTabs = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled("div")`
  margin-top: 1.5rem;
`;
const SaveButton = styled(Button)`
  margin-top: 10px;
  background-color: #3182bd;
  text-transform: uppercase;
  width: 100%;
  padding: 14px 10px;
  font-weight: 700;
  border-radius: 20px;
  font-size: 1rem;
`;

const CancelButton = styled(SaveButton)`
  background-color: white;
  color: #3182bd;
  border: 1px solid #3182bd;
`;

const TabItem = styled("div")`
  font-size: 1rem;
  font-weight: 600;
  flex: 1;
  border: 1px solid #3182bd;
  background-color: ${(props) => (props.active ? "#3182bd" : "white")};
  color: ${(props) => (props.active ? "white" : "#333333")};
  padding: 5px 0px;
`;

const ModalContent = styled("div")`
  width: 100%;
  color: black;
  ${TabItem}:first-child {
    border-radius: 4px 0 0 4px;
  }

  ${TabItem}:last-child {
    border-radius: 0 4px 4px 0;
  }
`;
