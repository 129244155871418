import { MICRO_TOPICS, MACRO_TOPICS } from "../constants/topics";

export default function hasMicroAndMacroTopics({ subject, weeks }) {
  if (subject !== "Micro" && subject !== "Macro") {
    return false;
  }

  let microUniqueTopics = MICRO_TOPICS.filter(
    (topic) => !MACRO_TOPICS.includes(topic)
  );
  let macroUniqueTopics = MACRO_TOPICS.filter(
    (topic) => !MICRO_TOPICS.includes(topic)
  );
  let microTopicFound = false;
  let macroTopicFound = false;
  weeks.forEach((week) => {
    week.topic.forEach((topic) => {
      if (microUniqueTopics.includes(topic)) {
        microTopicFound = true;
      }
      if (macroUniqueTopics.includes(topic)) {
        macroTopicFound = true;
      }
    });
  });

  if (microTopicFound && macroTopicFound) {
    return true;
  }

  return false;
}
