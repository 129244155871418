import React from "react";
import TopicContainer from "./TopicContainer";
import xMarkSrc from "../../../assets/images/x-mark.png";
import styled from "styled-components";

export default function RemovableTopicItem({
  onRemove,
  topicName,
  customStyles,
}) {
  return (
    <RemovableTopicContainer style={customStyles}>
      <label>{topicName}</label>
      <RemoveButton onClick={onRemove}>
        <XIcon />
      </RemoveButton>
    </RemovableTopicContainer>
  );
}

const RemovableTopicContainer = styled(TopicContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  width: 100%;
`;

const RemoveButton = styled("button")`
  background-color: transparent;
  border: none;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  img {
    width: 15px;
    height: auto;
  }
`;

const XIcon = styled("img").attrs({ src: xMarkSrc, alt: "X Icon" })``;
