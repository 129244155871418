import React, { useCallback, useEffect, useState } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import SemesterTabs from "./SemesterTabs";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import WarningMessage from "../shared/WarningMessage";
import DragAndDrop from "./DragAndDrop";
import DragAndDropTutorial from "./DragAndDropTutorial";
import useUser from "../../hooks/useUser";
import useSemester from "../../hooks/useSemester";
import { saveUserAsync, updateUser } from "../../helpers/back4app";
import hasMicroAndMacroTopics from "../../utils/hasMicroAndMacroTopics";
import NewSemesterSubmissionConfirmation from "../confirmation/NewSemesterSubmissionConfirmation";
import Swal from "sweetalert2";

export default function SemesterTabManager({
  urlParams,
  classStartDate,
  updateOnly,
  activeSemesterIdDuringUpdate,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [playTutorial, setPlayTutorial] = useState(false);
  const [didSubmitSchedule, setDidSubmitSchedule] = useState(false);

  const semester = urlParams?.semester;
  const transition = urlParams?.transition;

  const {
    semesterSchedules,
    createNewSemester,
    copyPreviousSemester,
    getActiveSemester,
    activateLatestSemester,
    setActiveSemesterId,
  } = useUser();

  const { loadApplicationSemesters, semesters } = useSemester();

  const handleSemesterSubmission = useCallback(async () => {
    setLoading(true);
    const userSemester = getActiveSemester();
    const isNew = semester && userSemester.semesterId === semester;
    const microAndMacro = hasMicroAndMacroTopics({
      subject: userSemester.subject,
      weeks: userSemester.weeks,
    });

    try {
      if (isNew) {
        await saveUserAsync({
          ...userSemester,
          microAndMacro,
        });
        setDidSubmitSchedule(true);
      } else {
        await updateUser(userSemester.id, {
          ...userSemester,
          updated: true,
          userUpdatedAt: new Date(),
          processedUpdate: false,
          microAndMacro,
        });
        Swal.fire({
          icon: "success",
          text: "Schedule updated successfully!",
        });
      }
    } catch (e) {
      Swal.fire({ icon: "error", text: e });
    }
    setLoading(false);
  }, [getActiveSemester, semester]);

  const handleStopTutorial = useCallback(() => {
    setPlayTutorial(false);
  }, []);

  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      setLoading(true);
      if (!updateOnly) {
        const semesterExists = semesterSchedules.find(
          (semester) => semester.semesterId === urlParams.semester
        );

        if (semesterExists) {
          setError(`A schedule with this semester already exists!`);
        } else {
          // Create a new semester for the user
          if (transition === "copy" && semesterSchedules.length >= 1) {
            copyPreviousSemester(urlParams, classStartDate);
          } else {
            createNewSemester(urlParams, classStartDate);
            if (!semesterSchedules.length) {
              // No active semesters, so play animation
              setPlayTutorial(true);
            }
          }
        }
      } else {
        // Activate given semester or latest if no semester is given
        if (activeSemesterIdDuringUpdate) {
          const userHasActiveSemesterId = semesterSchedules.some(
            (semester) => semester.semesterId === activeSemesterIdDuringUpdate
          );
          if (userHasActiveSemesterId) {
            setActiveSemesterId(activeSemesterIdDuringUpdate);
          } else {
            activateLatestSemester();
          }
        } else {
          activateLatestSemester();
        }
      }

      await loadApplicationSemesters();

      setLoading(false);
    }
  }, []);

  // Hide tutorial after 4 seconds
  useEffect(() => {
    setTimeout(() => {
      setPlayTutorial(false);
    }, 4000);
  }, []);

  return (
    <>
      {error && <WarningMessage message={error} />}
      {loading && <SpinnerOverlay loading={loading} />}

      {!error && semesterSchedules && semesters && !didSubmitSchedule && (
        <>
          {playTutorial ? (
            <DragAndDropTutorial onStopTutorial={handleStopTutorial} />
          ) : (
            <SemesterTabs>
              <DragAndDrop
                onSubmit={handleSemesterSubmission}
                semesterUrlParam={semester}
              />
            </SemesterTabs>
          )}
        </>
      )}
      {didSubmitSchedule && (
        <NewSemesterSubmissionConfirmation showAccountLink />
      )}
    </>
  );
}
