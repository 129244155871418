import React from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

const Bucket = ({ onDrop, weekNumber }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "topic",
    drop: (item) => {
      onDrop({
        name: item.name,
        currentWeekNumber: item.currentWeekNumber,
        weekNumber,
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <BucketContainer
      ref={drop}
      role={"Bucket"}
      isActive={isActive}
    ></BucketContainer>
  );
};

export default Bucket;

const BucketContainer = styled("div")`
  border: 1px dashed black;
  color: black;
  background-color: ${(props) =>
    props.isActive ? "rgba(49, 130, 189, 0.3)" : "white"};
  height: 25px;
  border-radius: 4px;
  padding: 1rem 1rem;
  width: 243px;
`;
