import React from "react";
import styled from "styled-components";

export default function DeleteConfirmation({ onDelete, items }) {
  return (
    <Root>
      <ConfirmHeader>
        Are you sure you want to delete these items?
      </ConfirmHeader>
      <DeleteList>
        <ul>
          {items.map((item, key) => {
            return (
              <li key={key}>
                <DeleteItem>{item}</DeleteItem>
              </li>
            );
          })}
        </ul>
      </DeleteList>

      <DeleterButtonContainer>
        <DeleteButton onClick={onDelete}>Delete</DeleteButton>
        <WarningText>THIS ACTION CANNOT BE UNDONE</WarningText>
      </DeleterButtonContainer>
    </Root>
  );
}

const Root = styled("div")`
  margin: 0 auto;
`;

const ConfirmHeader = styled("div")`
  font-size: 1.4rem;
  color: #545454;
`;

const DeleterButtonContainer = styled("div")`
  text-align: center;
`;

const DeleteButton = styled("button")`
  color: white;
  padding: 14px 20px;
  margin-top: 5px;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  font-size: 1rem;
  width: 200px;
  background-color: #f44336;
`;

const DeleteList = styled("div")`
  max-height: 350px;
  overflow-y: scroll;
`;

const DeleteItem = styled("div")`
  font-weight: 500;
`;

const WarningText = styled("div")`
  margin-top: 1rem;
  color: red;
  font-weight: 600;
`;
