import React from "react";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
import Button from "../shared/Button";

const LogoutButton = () => {
  const auth = useAuth();

  const handleLogout = () => {
    auth.signOut();
  };
  return (
    <Root>
      <Button onClick={handleLogout}>Logout</Button>
    </Root>
  );
};

export default LogoutButton;

const Root = styled("div")`
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5rem 0.5rem 0 0;
`;
