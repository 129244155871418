import React from "react";
import SemesterSubmissionConfirmation from "./SemesterSubmissionConfirmation";
import { ACCOUNT_SCHEDULE_UPDATE_ROUTE } from "../../constants/routes/accountRoutes";
import generateUrlWithParams from "../../utils/generateUrlWithParams";
import { Link } from "react-router-dom";

export default function SemesterTemplateSubmissionConfirmation({
  userEmail,
  semesterId,
  showAccountLink,
}) {
  return (
    <SemesterSubmissionConfirmation showAccountLink={showAccountLink}>
      <p>
        You're all set. We'll be sending you a weekly email chock full of
        resources that match the topic you're teaching the following week.
      </p>
      <p>
        Our mind readers try their best to guess your schedule. Click below to
        review and edit course topics.
      </p>
      {userEmail && semesterId && (
        <Link
          className="link-btn"
          to={`${ACCOUNT_SCHEDULE_UPDATE_ROUTE}${generateUrlWithParams([
            { key: "semester", value: semesterId },
            { key: "email", value: userEmail },
          ])}`}
        >
          Review Schedule
        </Link>
      )}
    </SemesterSubmissionConfirmation>
  );
}
