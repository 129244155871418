import React, { useState, useCallback } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import WeekTopicsManager from "./WeekTopicsManager";
import styled from "styled-components";
import useUser from "../../../hooks/useUser";
import useSemester from "../../../hooks/useSemester";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import WarningMessage from "../../shared/WarningMessage";
import EconInboxLogo from "../../shared/EconInboxLogo";
import NewSemesterSubmissionConfirmation from "../../confirmation/NewSemesterSubmissionConfirmation";
import { saveUserAsync, updateUser } from "../../../helpers/back4app";
import hasMicroAndMacroTopics from "../../../utils/hasMicroAndMacroTopics";
import Swal from "sweetalert2";

export default function MobileScheduler({
  classStartDate,
  urlParams,
  updateOnly,
  activeSemesterIdDuringUpdate,
}) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [didSubmitSchedule, setDidSubmitSchedule] = useState(false);

  const semester = urlParams?.semester;
  const transition = urlParams?.transition;

  const {
    activeSemesterId,
    semesterSchedules,
    createNewSemester,
    copyPreviousSemester,
    getActiveSemester,
    activateLatestSemester,
    setActiveSemesterId,
  } = useUser();

  const { loadApplicationSemesters, semesters } = useSemester();

  const handleSemesterSubmission = useCallback(async () => {
    setLoading(true);
    const userSemester = getActiveSemester();
    const filledWeeksCount = userSemester.weeks.filter(
      (week) => week.topic.length
    ).length;

    if (filledWeeksCount < 4) {
      Swal.fire({
        icon: "warning",
        title: "Oops...",
        text: "You must fill in at least 4 weeks!",
      });
      setLoading(false);
      return;
    }

    const isNew = semester && userSemester.semesterId === semester;
    const microAndMacro = hasMicroAndMacroTopics({
      subject: userSemester.subject,
      weeks: userSemester.weeks,
    });
    const swalProps = {
      icon: "success",
      title: "Success",
      confirmButtonText: "YAY!",
    };

    try {
      if (isNew) {
        await saveUserAsync({
          ...userSemester,
          microAndMacro,
        });
        Swal.fire({
          ...swalProps,
          allowOutsideClick: false,
          text: "Your schedule has been saved!",
          confirmButtonText: "Finish",
        }).then((result) => {
          // Show confirmation text...
          setDidSubmitSchedule(true);
        });
      } else {
        await updateUser(userSemester.id, {
          ...userSemester,
          updated: true,
          userUpdatedAt: new Date(),
          processedUpdate: false,
          microAndMacro,
        });
        Swal.fire({
          ...swalProps,
          text: "Schedule updated successfully!",
        });
      }
    } catch (e) {
      alert(e);
    }

    setLoading(false);
  }, [getActiveSemester, semester]);

  useAsyncEffect(async () => {
    setLoading(true);

    if (!updateOnly) {
      const semesterExists = semesterSchedules.find(
        (semester) => semester.semesterId === urlParams.semester
      );

      if (semesterExists) {
        setError(`A schedule with this semester already exists!`);
      } else {
        // Create a new semester for the user
        if (transition === "copy" && semesterSchedules.length >= 1) {
          copyPreviousSemester(urlParams, classStartDate);
        } else {
          createNewSemester(urlParams, classStartDate);
        }
      }
    } else {
      if (activeSemesterIdDuringUpdate) {
        const userHasActiveSemesterId = semesterSchedules.some(
          (semester) => semester.semesterId === activeSemesterIdDuringUpdate
        );
        if (userHasActiveSemesterId) {
          setActiveSemesterId(activeSemesterIdDuringUpdate);
        } else {
          activateLatestSemester();
        }
      } else {
        activateLatestSemester();
      }
    }

    await loadApplicationSemesters();
    setLoading(false);
  }, []);

  const activeSemester = getActiveSemester();

  return (
    <Root didSubmitSchedule={didSubmitSchedule}>
      {didSubmitSchedule && (
        <FeedbackMessageContainer>
          <NewSemesterSubmissionConfirmation showAccountLink />
        </FeedbackMessageContainer>
      )}
      {error && (
        <FeedbackMessageContainer>
          <WarningMessage message={error} />
        </FeedbackMessageContainer>
      )}
      {!error &&
        !didSubmitSchedule &&
        semesterSchedules &&
        activeSemesterId &&
        semesters &&
        activeSemester && (
          <>
            <EconInboxLogo />
            <Header>Build Your Class Schedule</Header>
            <WeekTopicsManager
              onSubmit={handleSemesterSubmission}
              semesterUrlParam={urlParams?.semester}
            />
          </>
        )}
      <SpinnerOverlay loading={loading} />
    </Root>
  );
}

const Root = styled("div")`
  text-align: center;
  height: 100vh;
  padding: 10px;
  overflow-y: auto;
`;

const FeedbackMessageContainer = styled("div")`
  margin-top: calc(50vh / 2);
`;

const Header = styled("div")`
  color: white;
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 10px;
`;
