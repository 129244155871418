import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import { getUser } from "../../../helpers/back4app";
import useUser from "../../../hooks/useUser";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import Button from "../../shared/Button";
import { ACCOUNT_DASHBOARD_ROUTE } from "../../../constants/routes/accountRoutes";
import EconInboxLogo from "../../shared/EconInboxLogo";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import getUrlParams from "../../../utils/getUrlParams";
import { EMAIL } from "../../../constants/scheduleBuilderUrlParams";

export default function AccountLoginPage() {
  const [email, setEmail] = useState("");
  const [isUserEmailSet, setIsUserEmailSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUserEmail } = useUser();
  const history = useHistory();

  const handleUpdateClick = useCallback(async () => {
    setLoading(true);
    getUser(email)
      .then(async (response) => {
        setLoading(true);
        if (!response.length) {
          Swal.fire({
            icon: "warning",
            text: `Email address not found.`,
            footer:
              '<a href="https://www.econinbox.com/signup/">Create an account</a>',
            width: 425,
          });
          setLoading(false);
        } else {
          setUserEmail(email);
          setIsUserEmailSet(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert(e);
      });
  }, [email, setUserEmail]);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        const { email } = await getUrlParams([EMAIL], false);
        if (email) {
          setEmail(email);
        }

        if (isUserEmailSet) {
          history.replace(ACCOUNT_DASHBOARD_ROUTE);
        }
      }
    },
    [isUserEmailSet]
  );

  return (
    <Root>
      <SpinnerOverlay loading={loading} />
      <LogoContainer>
        <EconInboxLogo />
      </LogoContainer>
      <LoginFormContainer>
        <EmailInput
          className="emailInput"
          type="text"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          value={email ?? ""}
          name="email"
          placeholder="Enter your email"
        />
        <AccessButton onClick={handleUpdateClick} className="updateSchedule">
          Access My Account
        </AccessButton>
      </LoginFormContainer>

      <Links>
        <li>
          <a href="https://www.econinbox.com">EconInbox Home</a>
        </li>
        <li>
          <a href="https://www.mru.org">MRU Home</a>
        </li>
      </Links>
    </Root>
  );
}

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const LogoContainer = styled("div")`
  margin-bottom: 2rem;
`;

const LoginFormContainer = styled("div")`
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
`;

const EmailInput = styled("input")`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  font-size: 1.25rem;
`;

const AccessButton = styled(Button)`
  font-size: 1.125rem;
  border: none;
  border-radius: 4px;
  color: white;
  margin-top: 1rem;
  background: #16c640;
  padding: 0.75rem;
  cursor: pointer;
  width: 100%;
`;

const Links = styled("ul")`
  margin-top: 1.25rem;
  list-style-type: none;
  padding: 0;

  a {
    color: #75cfff;
  }

  li:not(:first-child) {
    border-left: 1px solid #75cfff;
  }

  li {
    padding: 0.125rem 0;
    display: inline-block;
    font-size: 0.8rem;
    padding-right: 10px;
  }

  li:not(:first-child) {
    padding-left: 10px;
  }
`;
