import React from "react";
import CopySchedulerFormLabel from "./shared/CopySchedulerFormLabel";
import DatePickerInput from "../shared/DatePickerInput";
import Button from "../shared/Button";
import Row from "../shared/Row";
import CoursePacingsDropdown from "./CoursePacingsDropdown";
import styled from "styled-components";
import CourseLengthRadioSelection from "./CourseLengthRadioSelection";

export default function TemplateSchedulerForm({
  onSubmit,
  showCoursePacings,
  onStartDateChange,
  onCourseLengthChange,
  onCoursePacingChange,
}) {
  return (
    <div>
      <CopySchedulerFormLabel>Your course start date</CopySchedulerFormLabel>
      <DatePickerInput
        onFocus={(e) => e.target.blur()}
        placeholderText="Select date"
        onChange={(date) => onStartDateChange(date)}
      />
      <FormSection>
        <CopySchedulerFormLabel>Your course is</CopySchedulerFormLabel>
        <CourseLengthRadioSelection
          onChange={(value) => onCourseLengthChange(value)}
        />
      </FormSection>
      <FormSection hidden={!showCoursePacings}>
        <CopySchedulerFormLabel>
          You cover topics in the following order
        </CopySchedulerFormLabel>
        <CoursePacingsDropdown
          onChange={(value) => onCoursePacingChange(value)}
        />
      </FormSection>

      <SignUpButton onClick={onSubmit}>Sign Up</SignUpButton>
    </div>
  );
}

const FormSection = styled("div")`
  margin: 2rem 0;
  ${Row} {
    justify-content: space-around;
  }
  visibility: ${(props) => (props.hidden ? "hidden" : "visible")};
`;

const SignUpButton = styled(Button)`
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
`;
