import React, { useState } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ACCOUNT_LOGIN_ROUTE } from "../../../constants/routes/accountRoutes";
import useSemester from "../../../hooks/useSemester";
import useUser from "../../../hooks/useUser";
import EconInboxLogoBlue from "../../shared/EconInboxLogoBlue";
import AccountDashboardItems from "../AccountDashboardItems";
import SpinnerOverlay from "../../shared/SpinnerOverlay";
import rotatePhoneGraphicSrc from "../../../assets/images/rotate-phone-icon.svg";

export const AccountDashboardPage = () => {
  const [loading, setLoading] = useState(false);
  const appSemesters = useSemester();
  const user = useUser();
  const history = useHistory();

  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      setLoading(true);
      /**
       * Return to account login page if the user's
       * semester schedules have not been loaded yet.
       */
      const { email } = user;
      if (email) {
        await appSemesters.loadApplicationSemesters();
        await user.loadUserSemesters(email);
        setLoading(false);
      } else {
        history.push(ACCOUNT_LOGIN_ROUTE);
      }
    }
  }, []);

  return (
    <Root>
      <SpinnerOverlay loading={loading} />
      <Container>
        <div className="dashboard-items">
          <EconInboxLogo />
          {!loading && user.semesterSchedules.length > 0 && appSemesters && (
            <>
              <EmailContainer>{user.email}</EmailContainer>
              <AccountDashboardItems
                userSemesters={user.semesterSchedules}
                appSemestersContext={appSemesters}
              />
            </>
          )}
        </div>
        <RotateNotice className="rotate-notice">
          <p>Please rotate your phone</p>
          <RotatePhoneGraphic />
        </RotateNotice>
      </Container>
    </Root>
  );
};

const Root = styled("div")`
  background-color: white;
  width: 100%;
  height: 100vw;
  color: #808080;
  .rotate-notice {
    display: none;
  }

  @media (max-width: 600px) {
    background-color: #3182bd;
    .rotate-notice {
      display: block;
    }
    .dashboard-items {
      display: none;
    }
  }
`;

const Container = styled("div")`
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 760px;
  width: 100%;
  padding: 1rem;
`;

const EconInboxLogo = styled(EconInboxLogoBlue)`
  margin: 0 auto 1.5rem auto;
`;

const EmailContainer = styled("div")`
  display: flex;
  justify-items: start;
  color: #606060;
  font-size: 1.2rem;
  margin-left: 1.75rem;
  font-style: italic;
`;

const RotateNotice = styled("div")`
  p {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  color: white;
  text-align: center;
`;

const RotatePhoneGraphic = styled("img").attrs({
  src: rotatePhoneGraphicSrc,
  alt: "Rotate Phone Graphic",
})`
  height: 120px;
  width: auto;
`;
