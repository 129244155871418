export default function getClosestWeekWithTopicsEndIndex(weeksArray) {
  if (!weeksArray[weeksArray.length - 1].topic.length) {
    for (let i = weeksArray.length - 1; i > 0; i--) {
      if (weeksArray[i].topic.length > 0) {
        return i;
      }
    }
  } else {
    return weeksArray.length - 1;
  }
}
