import React, { useCallback, useEffect, useState } from "react";
import useAsyncEffect from "@emberex/react-utils/lib/useAsyncEffect";
import DraggableBox from "../dnd/DraggableBox";
import Column from "../shared/Column";
import useUser from "../../hooks/useUser";
import AddCustomTopicForm from "./AddCustomTopicForm";
import Button from "../shared/Button";
import styled from "styled-components";
import TopicsBucket from "./TopicsBucket";
import SubjectToggle from "./SubjectToggle";
import CompletionTrackerSubmit from "./CompletionTrackerSubmit";
import { calculateCompletion } from "./helper/calculations";
import getSelectedSubject from "./helper/getSelectedSubject";
import {
  MICRO_TOPICS,
  MACRO_TOPICS,
  INTRO_TOPICS,
} from "../../constants/topics";
import SpinnerOverlay from "../shared/SpinnerOverlay";
import useSemester from "../../hooks/useSemester";

export default function DragAndDrop({ onSubmit, semesterUrlParam }) {
  const [loading, setLoading] = useState(false);
  const [semesterDisplayName, setSemesterDisplayName] = useState();
  const [activeUserSemester, setActiveUserSemester] = useState();
  const [activeSemesterWeeks, setActiveSemesterWeeks] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("micro");
  const [isNewSemester, setIsNewSemester] = useState(false);
  const [progress, setProgress] = useState(0);

  const {
    getActiveSemester,
    activeSemesterId,
    addTopicToActiveSemester,
    removeTopicFromActiveSemester,
    addCustomTopicToActiveSemester,
    removeWeekFromSemesterSchedule,
    addWeekToSemesterSchedule,
  } = useUser();

  const { getSemesterFromUserRecord } = useSemester();

  useEffect(() => {
    // Only update subject when user changes semester tabs
    const activeSemesterSchedule = getActiveSemester();
    const subject = getSelectedSubject(activeSemesterSchedule.subject);

    setSelectedSubject(subject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSemesterId]);

  useAsyncEffect(async () => {
    setLoading(true);
    const activeSemesterSchedule = getActiveSemester();
    const semesterObj = getSemesterFromUserRecord(activeSemesterSchedule);

    setProgress(calculateCompletion(activeSemesterSchedule.weeks));
    setIsNewSemester(
      semesterUrlParam && semesterUrlParam === activeSemesterSchedule.semesterId
    );
    setActiveSemesterWeeks(
      activeSemesterSchedule.weeks.map((week) => {
        return {
          weekNumber: week.numberOfWeek,
          dateLabel: week.label,
          topics: week.topic,
          startDate: week.date,
        };
      })
    );

    setActiveUserSemester(activeSemesterSchedule);
    setSemesterDisplayName(semesterObj.displayName);
    setLoading(false);
  }, [activeSemesterId, getActiveSemester, semesterUrlParam]);

  const handleSubjectChange = useCallback((subject) => {
    setSelectedSubject(subject);
  }, []);

  const handleTopicDeletion = useCallback(
    (topicName, weekNumber) => {
      removeTopicFromActiveSemester(topicName, weekNumber);
    },
    [removeTopicFromActiveSemester]
  );

  const handleTopicAddition = useCallback(
    (item) => {
      const { weekNumber, name, currentWeekNumber } = item;
      const topicName = name;
      const activeSemesterSchedule = getActiveSemester();
      const activeWeek = activeSemesterSchedule.weeks.find(
        (week) => week.numberOfWeek === weekNumber
      );

      if (
        currentWeekNumber !== undefined &&
        !activeWeek.topic.includes(topicName)
      ) {
        // Delete previous topic when dragging in between weeks
        handleTopicDeletion(name, currentWeekNumber);
      }

      addTopicToActiveSemester(topicName, weekNumber);
    },
    [addTopicToActiveSemester, getActiveSemester, handleTopicDeletion]
  );

  const handleCustomTopicAddition = useCallback(
    (topicName) => {
      if (topicName) {
        addCustomTopicToActiveSemester(topicName);
      }
    },
    [addCustomTopicToActiveSemester]
  );

  const handleAddWeek = useCallback(() => {
    addWeekToSemesterSchedule();
  }, [addWeekToSemesterSchedule]);

  const handleRemoveWeek = useCallback(() => {
    removeWeekFromSemesterSchedule();
  }, [removeWeekFromSemesterSchedule]);

  const subjects =
    selectedSubject === "micro"
      ? MICRO_TOPICS
      : selectedSubject === "intro"
      ? INTRO_TOPICS
      : MACRO_TOPICS;

  return (
    <>
      {activeUserSemester && activeSemesterId && activeSemesterWeeks ? (
        <Root>
          <Header>BUILD YOUR CLASS SCHEDULE</Header>
          <TopicsColumn>
            <UserWeeks>
              <ColumnHeader>Your {semesterDisplayName} Schedule</ColumnHeader>
              {activeSemesterWeeks.map((week, index) => {
                return (
                  <TopicsBucket
                    weekIndex={index}
                    date={week.startDate}
                    topics={week.topics}
                    weekNumber={week.weekNumber}
                    onTopicDrop={handleTopicAddition}
                    onRemoveTopic={handleTopicDeletion}
                    activeSemesterId={activeSemesterId}
                    key={week.dateLabel}
                  />
                );
              })}
              <WeekActions>
                <RemoveButton onClick={handleRemoveWeek}>
                  - Remove Week
                </RemoveButton>
                <AddButton onClick={handleAddWeek}>+ Add Week</AddButton>
              </WeekActions>
            </UserWeeks>
            <Topics>
              <ColumnHeader>TOPIC</ColumnHeader>
              <Instructions>
                Drag the topics to the corresponding week on the left
              </Instructions>
              <Column>
                <SubjectToggle
                  onChange={handleSubjectChange}
                  defaultValue={selectedSubject}
                  visibility={selectedSubject !== "intro"}
                />
              </Column>
              <Column>
                <TopicsContainer>
                  {subjects.map((topic) => {
                    return (
                      <DraggableBox
                        name={topic}
                        key={topic}
                        type={activeSemesterId}
                      />
                    );
                  })}
                </TopicsContainer>
                <CustomTopicsContainer>
                  <AddCustomTopicForm
                    onAddCustomTopic={handleCustomTopicAddition}
                  />
                  {activeUserSemester.customTopics.map((topic) => {
                    return (
                      <DraggableBox
                        name={topic}
                        key={topic}
                        type={activeSemesterId}
                        borderColor="#3282bd"
                      />
                    );
                  })}
                  <CompletionTrackerSubmit
                    isNew={isNewSemester}
                    onSubmit={onSubmit}
                    percentage={progress}
                    weeks={activeUserSemester.weeks}
                  />
                </CustomTopicsContainer>
              </Column>
            </Topics>
          </TopicsColumn>
          <CompletionTrackerSubmit
            isNew={isNewSemester}
            onSubmit={onSubmit}
            weeks={activeUserSemester.weeks}
            buttonOnly
          />
        </Root>
      ) : (
        <SpinnerOverlay loading={loading} />
      )}
    </>
  );
}

const Root = styled("div")`
  width: 1050px;
  text-align: center;
  color: black;
  background-color: white;
  padding: 50px 50px;
  border: 2px solid white;
  margin-left: auto;
  margin-right: auto;
`;

const Header = styled("h1")`
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 30px;
`;

const TopicsColumn = styled(Column)`
  align-items: flex-start;
  flex-shrink: 0;
`;

const ColumnHeader = styled("div")`
  font-size: 17px;
  margin-bottom: 10px;
`;

const TopicsContainer = styled("div")`
  flex-shrink: 0;
`;

const CustomTopicsContainer = styled("div")`
  margin-left: 2rem;
`;

const Instructions = styled("div")`
  font-size: 14px;
`;

const Topics = styled("div")`
  background-color: #e0e0e0;
  padding: 1rem 2rem;
`;

const UserWeeks = styled("div")``;

const WeekActions = styled("div")`
  margin-top: 2rem;
`;

const AddButton = styled(Button)`
  width: 100%;
  background-color: white;
  border: 1px solid #3282bd;
  color: #3282bd;
  :hover {
    background-color: #3282bd;
    color: white;
  }
`;

const RemoveButton = styled(Button)`
  width: 100%;
  background-color: white;
  border: 1px solid #c12828;
  color: #c12828;
  :hover {
    background-color: #c12828;
    color: white;
  }
`;
